// NewsletterForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { setAlert } from '../actions/alert';
import { Link } from 'react-router-dom';
import shape_06 from '../assets/images/shape/title_shape_06.svg';
import realtor from '../assets/images/media/realtor.png';
import { connect } from 'react-redux';

const NewsletterForm = (props) => {
    const { setAlert } = props;
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const token = localStorage.getItem('token');
        console.log('Token:', token);  // Debugging line

        if (!token) {
            setAlert('No token found. Please log in.', 'error');
            setLoading(false);
            return;
        }

        try {
            const res = await axios.post(
                `${'https://www.pevikholdings.co.ke'}/api/newsletter/`,
                { email },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (res.status === 200 || res.status === 201) {
                setAlert('Email Sent', 'success');
                setEmail('');
                setLoading(false);
                window.scrollTo(0, 0);
            } else {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
        } catch (error) {
            console.error(error);
            setAlert('Error with Sending Email', 'error');
            setLoading(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className="fancy-banner-two position-relative z-1 pt-90 lg-pt-50 pb-90 lg-pb-50" style={{ backgroundImage: `url(${realtor})` }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="title-one text-center text-lg-start md-mb-40 pe-xl-5">
                            <h3 className="text-white m0">Start your <span>Journey<img src={shape_06} alt="" className="lazy-img" /></span> As a Retailer.</h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-wrapper me-auto ms-auto me-lg-0">
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    placeholder="Enter mail address"
                                    className="type-input"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <div>
                                    <button>
                                        {loading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                <ThreeDots type="Oval" color="#ffffff" height={50} width={50} timeout={3000} radius="9" ariaLabel="three-dots-loading" />
                                            </div>
                                        ) : (
                                            'Schedule Tour'
                                        )}
                                    </button>
                                </div>
                            </form>
                            <div className="fs-16 mt-10 text-white">Already an Agent? <Link to='/signup' data-bs-toggle="modal" data-bs-target="/">Sign in.</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    setAlert,
};

export default connect(null, mapDispatchToProps) (NewsletterForm);
