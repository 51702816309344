// ModalAuth.js
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Login from './Login';
import SignUp from './SignUp';
import Alert from './Alert';
import { closeModal } from '../actions/auth';

function ModalAuth({ isModalOpen, closeModal }) {
    return (
        <>
            <Alert />
            <Modal
                size="lg"
                show={isModalOpen}
                onHide={closeModal}
                aria-labelledby="login-modal"
                centered
            >
                <Modal.Body>
                    <div className="container">
                        <div className="user-data-form modal-content">
                            <div className="form-wrapper m-auto">
                                <Tabs
                                    defaultActiveKey="Login"
                                    id="login-tab"
                                    className="mb-3"
                                    justify
                                >
                                    <Tab eventKey="Login" title="Login">
                                        <Login />
                                    </Tab>
                                    <Tab eventKey="signup" title="SignUp">
                                        <SignUp />
                                    </Tab>
                                </Tabs>
                                {/* Additional content */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => ({
    isModalOpen: state.auth.isModalOpen,
});
  
const mapDispatchToProps = {
    closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAuth);
