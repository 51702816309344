import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Fancybox from '../components/FancyboxListing';
import { setAlert } from '../actions/alert';
import { connect } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import ReactHtmlParser from 'react-html-parser';
import { Stack } from 'react-bootstrap';
import ils_07 from '../assets/images/assets/ils_07.svg';

const ListingDetail = (props) => {
    const { setAlert } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let { id } = useParams();
    const [listing, setListing] = useState({});
    const [realtor, setRealtor] = useState({});
    const [price, setPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        phone_number: '',
        available_day: '',
        message: ''
    });
    const [isLoading, setIsLoading] = useState(true);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const slug = id;
                const config = {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                };

                const res = await axios.get(`${'https://www.pevikholdings.co.ke'}/api/listings/${slug}`, config);
                setListing(res.data);
                setPrice(numberWithCommas(res.data.price));
                setIsLoading(false);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    setError("Please log in to view project.");
                } else {
                    setError("Or an error occurred.");
                }
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        const fetchRealtorData = async () => {
            try {
                const id = listing.realtor;
                if (id) {
                    const config = {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    };
                    const res = await axios.get(`${'https://www.pevikholdings.co.ke'}/api/realtors/${id}`, config);
                    setRealtor(res.data);
                }
            } catch (error) {
                console.error('Error fetching realtor data: ', error);
            }
        };

        fetchRealtorData();
    }, [listing.realtor]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const res = await axios.post(`${'https://www.pevikholdings.co.ke'}/api/schedule/`, formValues);
            if (res.status === 200 || res.status === 201) {
                setAlert('Schedule Message Sent', 'success');
                setFormValues({
                    name: '',
                    email: '',
                    phone_number: '',
                    available_day: '',
                    message: ''
                });
                setLoading(false);
                window.scrollTo(0, 0);
            } else {
                throw new Error(`HTTP error! status: ${res.status}`);
            }
        } catch (error) {
            console.error(error)
            setAlert('Error with Sending Message', 'error');
            setLoading(false);
            window.scrollTo(0, 0);
        }
    };

    if (isLoading) {
        return <div className="main-page-wrapper">
            <div class="inner-banner-two inner-banner z-1 pt-160 lg-pt-130 pb-160 xl-pb-120 md-pb-80 position-relative" style={{ backgroundImage: `url(${ils_07})` }}>
                <div class="container">
                    <div class="row pt-40 theme-details-one">
                        <div class="col-lg-8">
                            <h2 class="property-title">listing Details</h2>
                            <div class="d-flex flex-wrap mt-10">
                                <div class="list-type text-uppercase border-20 mt-15 me-3">Listing Type</div>
                                <div class="address mt-15"><i class="bi bi-geo-alt"></i> Location</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="d-inline-block md-mt-40">
                                <div class="price color-dark fw-500">Price: Ksh _______</div>
                                <div class="est-price fs-20 mt-25 mb-35 md-mb-30">Est. Payment <span class="fw-500 color-dark">Ksh
                                    _______/mo*</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listing-details-one theme-details-one bg-green-one pt-180 lg-pt-150 pb-150 xl-pb-120">
                <div class="container">
                    <div class="media-gallery bg-white shadow4 p-40 border-20 mt-80 lg-mt-50 mb-60">
                        <div id="media_slider" class="carousel slide row style-two">
                            <div class="col-12">
                                <div class="position-relative z-1 overflow-hidden border-20">
                                    <div class="img-fancy-btn border-10 fw-500 fs-16 color-dark">
                                        Sell all Photos
                                    </div>
                                    <div class="theme-sidebar-one d-none d-xl-block">
                                        <div class="agent-info bg-white border-20 p-30">
                                            <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                                <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={500} height={400} margin={20} />
                                                <Skeleton varient="rounded" width={370} height={70} />
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="position-relative mt-25 xs-mt-10">
                                    <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                        <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={1350} height={600} margin={20} />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-8">
                            <div class="bg-white shadow4 border-20">
                                <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                    <center>
                                        <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={895} height={518} margin={20} />
                                    </center>
                                </Stack>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-8 me-auto ms-auto">
                            <div class="theme-sidebar-one ms-xl-5 lg-mt-80" >
                                <div class="tour-schedule bg-white border-20 p-30 mb-40">
                                    <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                        <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={350} height={600} margin={20} />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    };

    if (error) {
        return <div className="main-page-wrapper">
            <div className="pt-110"></div>
            <div className="listing-details-one theme-details-one pt-180 lg-pt-150 pb-150 xl-pb-120">
                <div className="container">
                    <center><h5>Listing not found.{error}</h5></center>
                </div>
            </div>
        </div>;
    }

    if (!listing) {
        return <div className="main-page-wrapper">
            <div className="pt-110"></div>
            <div className="listing-details-one theme-details-one bg-green-one pt-180 lg-pt-150 pb-150 xl-pb-120">
                <div className="container">
                    <h5>Listing not found.</h5>
                </div>
            </div>
        </div>;
    }


    return (
        <div class="main-page-wrapper">
            <Helmet>
                <title>Pevik Holdings | {`${listing.property_type}`} | {`${listing.title}`}</title>
                <meta
                    name='description'
                    content='Listing detail'
                />
            </Helmet>
            <div class="main-page-wrapper bg-green-one">
                <div class="inner-banner-two inner-banner z-1 pt-160 lg-pt-130 pb-160 xl-pb-120 md-pb-80 position-relative" style={{ backgroundImage: `url(${ils_07})` }}>
                    <div class="container">
                        <div class="row pt-40 theme-details-one">
                            <div class="col-lg-8">
                                <h2 class="property-title">{listing.title}</h2>
                                <div class="d-flex flex-wrap mt-10">
                                    <div class="list-type text-uppercase border-20 mt-15 me-3">FOR RENT</div>
                                    <div class="address mt-15"><i class="bi bi-geo-alt"></i> {listing.city}, {listing.state}, {listing.zipcode}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="d-inline-block md-mt-40">
                                    <div class="price color-dark fw-500">Price: Ksh {price}</div>
                                    <div class="est-price fs-20 mt-25 mb-35 md-mb-30">Est. Payment <span class="fw-500 color-dark">Ksh
                                        {price}/mo*</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listing-details-one theme-details-one bg-green-one pt-40 lg-pt-80 pb-150 xl-pb-120">
                <div class="container">
                    <div class="media-gallery bg-white shadow4 p-40 border-20 mt-80 lg-mt-50 mb-60">
                        <div id="media_slider" class="carousel slide row style-two">
                            <div class="col-12">
                                <div class="position-relative z-1 overflow-hidden border-20">
                                    <div class="img-fancy-btn border-10 fw-500 fs-16 color-dark">
                                        Sell all Photos
                                        <Fancybox
                                            options={{
                                                Carousel: {
                                                    infinite: false,
                                                },
                                            }}
                                        >
                                            <a data-fancybox="gallery" href={props.photo_1} data-caption={props.title} class="d-block">
                                                <img src={props.photo_1} alt="" width={1920} height={1080} />
                                            </a>
                                            <a data-fancybox="gallery" href={props.photo_2} data-caption={props.title} class="d-block">
                                                <img src={props.photo_2} alt="" width={1920} height={1080} />
                                            </a>
                                            <a data-fancybox="gallery" href={props.photo_3} data-caption={props.title} class="d-block">
                                                <img src={props.photo_3} alt="" width={1920} height={1080} />
                                            </a>
                                            <a data-fancybox="gallery" href={props.photo_4} data-caption={props.title} class="d-block">
                                                <img src={props.photo_4} alt="" width={1920} height={1080} />
                                            </a>
                                        </Fancybox>

                                    </div>
                                    <div class="theme-sidebar-one d-none d-xl-block">
                                        <div class="agent-info bg-white border-20 p-30">
                                            <img src={realtor.photo} alt=""
                                                class="lazy-img rounded-circle ms-auto me-auto avatar" />
                                            <div class="text-center mt-25 xl-mt-20">
                                                <h6 class="name">{realtor.name}</h6>
                                                <p class="fs-16">Property Realtor Agent</p>
                                                <p class="fs-16">{realtor.description}</p>
                                                <ul class="style-none d-flex align-items-center justify-content-center social-icon">
                                                    <li><Link to="#"><i class="fa-brands fa-facebook-f"></i></Link></li>
                                                    <li><Link to="#"><i class="fa-brands fa-twitter"></i></Link></li>
                                                    <li><Link to="#"><i class="fa-brands fa-instagram"></i></Link></li>
                                                    <li><Link to="#"><i class="fa-brands fa-linkedin"></i></Link></li>
                                                </ul>
                                            </div>
                                            <div class="divider-line mt-40 xl-mt-30 mb-45 pt-20">
                                                <ul class="style-none">
                                                    <li>Location: <span>{listing.address}</span></li>
                                                    <li>Email: <span><a href={`mailto:${realtor.email}`}>{realtor.email}</a></span>
                                                    </li>
                                                    <li>Phone: <span><a href={`tel:${realtor.phone}`}>{realtor.phone}</a></span></li>                                                </ul>
                                            </div>

                                            <a href={`tel:${realtor.phone}`} class="btn-nine text-uppercase rounded-3 w-100 mb-10">CONTACT AGENT</a>
                                        </div>

                                    </div>

                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img src={listing.photo_1} alt="" class="border-20 w-100" width={1340} height={740} />
                                        </div>
                                        <div class="carousel-item">
                                            <img src={listing.photo_2} alt="" class="border-20 w-100" width={1340} height={740} />
                                        </div>
                                        <div class="carousel-item">
                                            <img src={listing.photo_3} alt="" class="border-20 w-100" width={1340} height={740} />
                                        </div>
                                        <div class="carousel-item">
                                            <img src={listing.photo_4} alt="" class="border-20 w-100" width={1340} height={740} />
                                        </div>
                                        <div class="carousel-item">
                                            <img src={listing.photo_5} alt="" class="border-20 w-100" width={1340} height={740} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="position-relative mt-25 xs-mt-10">
                                    <div
                                        class="carousel-indicators d-flex justify-content-between justify-content-xl-start position-relative w-100 h-100">
                                        <button type="button" data-bs-target="#media_slider" data-bs-slide-to="0" class="active"
                                            aria-current="true" aria-label="Slide 1">
                                            <img src={listing.photo_1} alt="" class="border-10 w-100" width={187} height={120} />
                                        </button>
                                        <button type="button" data-bs-target="#media_slider" data-bs-slide-to="1"
                                            aria-label="Slide 2">
                                            <img src={listing.photo_2} alt="" class="border-10 w-100" width={187} height={120} />
                                        </button>
                                        <button type="button" data-bs-target="#media_slider" data-bs-slide-to="2"
                                            aria-label="Slide 3">
                                            <img src={listing.photo_3} alt="" class="border-10 w-100" width={187} height={120} />
                                        </button>
                                        <button type="button" data-bs-target="#media_slider" data-bs-slide-to="3"
                                            aria-label="Slide 4">
                                            <img src={listing.photo_4} alt="" class="border-10 w-100" width={187} height={120} />
                                        </button>
                                        <button type="button" data-bs-target="#media_slider" data-bs-slide-to="4"
                                            aria-label="Slide 5">
                                            <img src={listing.photo_5} alt="" class="border-10 w-100" width={187} height={120} />
                                        </button>
                                        <button type="button" data-bs-target="#media_slider" data-bs-slide-to="5"
                                            aria-label="Slide 6">
                                            <img src={listing.photo_6} alt="" class="border-10 w-100" width={187} height={120} />
                                        </button>
                                    </div>
                                    <div class="carousel-arrow d-none d-xl-flex">
                                        <button class="carousel-control-prev" type="button" data-bs-target="#media_slider"
                                            data-bs-slide="prev">
                                            <i class="bi bi-chevron-left"></i>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#media_slider"
                                            data-bs-slide="next">
                                            <i class="bi bi-chevron-right"></i>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-8">
                            <div class="bg-white shadow4 border-20">
                                <div class="property-overview p-40">
                                    <h4 class="mb-20">Overview</h4>
                                    <p className='fs-20 lh-lg'></p>
                                    {ReactHtmlParser(listing.description)}
                                </div>

                                <div class="property-feature-accordion border-top p-40">
                                    <h4 class="mb-20">Property Features</h4>

                                    <div class="feature-list-two">
                                        <ul class="style-none d-flex flex-wrap justify-content-between">
                                            <li><span>Status: </span> <span class="fw-500 color-dark">{listing.sale_type}</span></li>
                                            <li><span>Property Type: </span> <span class="fw-500 color-dark">{listing.property_type}</span></li>
                                        </ul>
                                        {listing.features && listing.features.split(',').length > 0 && (
                                            <ul className="style-none d-flex flex-wrap justify-content-between list-style-two">
                                                {listing.features.split(',').map((feature, index) => (
                                                    <li key={index}>{feature.trim()}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                <div class="property-video-tour border-top p-40">
                                    <h4 class="mb-30">Video Tour</h4>
                                    <div class="position-relative border-15 image-bg overflow-hidden z-1">
                                        <img src={listing.photo_1} alt=""
                                            class="lazy-img w-100" />
                                        <Fancybox
                                            options={{
                                                Carousel: {
                                                    infinite: false,
                                                },
                                            }}>
                                            <a class="video-icon tran3s rounded-circle d-flex align-items-center justify-content-center"
                                                data-fancybox href={listing.video_url} data-caption={listing.title}>
                                                <i class="fa-thin fa-play"></i>
                                            </a>
                                        </Fancybox>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-8 me-auto ms-auto">
                            <div class="theme-sidebar-one ms-xl-5 lg-mt-80" >
                                <div class="tour-schedule bg-white border-20 p-30 mb-40">
                                    <h5 class="mb-40">Schedule Tour</h5>
                                    <form onSubmit={handleSubmit} id="tour-form">
                                        <div className="input-box-three mb-25">
                                            <div className="label">Your Name*</div>
                                            <input
                                                type="text"
                                                placeholder="Your full name"
                                                className="type-input"
                                                id="name"
                                                value={formValues.name}
                                                onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                                                required
                                            />
                                        </div>

                                        <div className="input-box-three mb-25">
                                            <div className="label">Your Email*</div>
                                            <input
                                                type="email"
                                                placeholder="Enter mail address"
                                                className="type-input"
                                                id="email"
                                                value={formValues.email}
                                                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                                required
                                            />
                                        </div>

                                        <div className="input-box-three mb-25">
                                            <div className="label">Your Phone*</div>
                                            <input
                                                type="tel"
                                                placeholder="Your phone number"
                                                className="type-input"
                                                id="phone_number"
                                                value={formValues.phone_number}
                                                onChange={(e) => setFormValues({ ...formValues, phone_number: e.target.value })}
                                                required
                                            />
                                        </div>

                                        <div className="input-box-three mb-25">
                                            <div className="label">Select Date*</div>
                                            <input
                                                type="date"
                                                className="type-input"
                                                id="available_day"
                                                value={formValues.available_day}
                                                onChange={(e) => setFormValues({ ...formValues, available_day: e.target.value })}
                                                required
                                            />
                                        </div>

                                        <div className="input-box-three mb-15">
                                            <div className="label">Message*</div>
                                            <textarea
                                                placeholder="Hello, I am interested in this (property name)"
                                                id="message"
                                                value={formValues.message}
                                                onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
                                                required
                                            ></textarea>
                                        </div>

                                        <div>
                                            <button className="btn-two w-100 text-uppercase d-block mt-20">
                                                {loading ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                        <ThreeDots type="Oval" color="#ffffff" height={50} width={50} timeout={3000} radius="9" ariaLabel="three-dots-loading" />
                                                    </div>
                                                ) : (
                                                    'Schedule Tour'
                                                )}
                                            </button>
                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* End Listing Detail Area */}
        </div>
    );
};

const mapDispatchToProps = {
    setAlert,
};

export default connect(null, mapDispatchToProps)(ListingDetail);
