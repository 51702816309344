import React from 'react';
import PropTypes from 'prop-types';
import Fancybox from "./Fancybox";
import AuthenticatedLink from '../hocs/AuthenticatedLink.js';
import AuthListLinkIcon from '../hocs/AuthListLinkIcon.js';
const Card = (props) => {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const trimTitle = (title, maxWords) => {
    const words = title.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return title;
  };

  return (

    <div class="listing-card-one style-three border-30 mb-50">
      <div class="img-gallery p-15">
        <div class="position-relative border-20 overflow-hidden">
          {props.sale_type === 'For Sale' ? (
            <div class="tag sale border-25">{props.sale_type}</div>
          ) : (
            <div class="tag border-25">{props.sale_type}</div>
          )}
          <img src={props.photo_main} class="w-100 border-20" alt="..." width={400} height={250} />

          <AuthListLinkIcon
            to={`/listings/${props.slug}`}
            className="btn-four inverse rounded-circle position-absolute"
            isAuthenticated={props.isAuthenticated}
          >
            <i class="bi bi-arrow-up-right"></i>
          </AuthListLinkIcon>
          <div class="img-slider-btn">

            <Fancybox
              options={{
                Carousel: {
                  infinite: false,
                },
              }}
            >
              04 <i class="fa-regular fa-image"></i>
              <a data-fancybox="gallery" href={props.photo_1} data-caption={props.title} >
                <img
                  alt=""
                  src={props.photo_1}
                  width="200"
                  height="150"
                />
              </a>
              <a data-fancybox="gallery" href={props.photo_2} data-caption={props.title} >
                <img
                  alt=""
                  src={props.photo_2}
                  width="200"
                  height="150"
                />
              </a>
              <a data-fancybox="gallery" href={props.photo_3} data-caption={props.title} >
                <img
                  alt=""
                  src={props.photo_3}
                  width="200"
                  height="150"
                />
              </a>
              <a data-fancybox="gallery" href={props.photo_4} data-caption={props.title} >
                <img
                  alt=""
                  src={props.photo_4}
                  width="200"
                  height="150"
                />
              </a>
            </Fancybox>
          </div>
        </div>
      </div>
      <div class="property-info pe-4 ps-4">
        <AuthenticatedLink slug={props.slug} title={trimTitle(props.title, 4)} />
        <div class="address"><i class="fa-duotone fa-location-dot"></i>{props.address}, {props.city}</div>
        <ul class="style-none feature d-flex flex-wrap align-items-center justify-content-between">
          {props.bedrooms && (
            <li class="d-flex align-items-center">
              <img src="assets/images/icon/icon_05.svg" alt="" class="lazy-img icon me-2" />
              <span class="fs-16">{props.bedrooms} Bed</span>
            </li>
          )}
          {props.bathrooms && (
            <li class="d-flex align-items-center">
              <img src="assets/images/icon/icon_06.svg" alt="" class="lazy-img icon me-2" />
              <span class="fs-16">{props.bathrooms} Bath</span>
            </li>
          )}
          <li class="d-flex align-items-center">
            <i class="fa-thin fa-ballot-check lazy-img icon me-2"></i>
            <span class="fs-16"> {props.property_type}</span>
          </li>
          <li class="d-flex align-items-center">
            <img src="assets/images/icon/icon_04.svg" alt="" class="lazy-img icon me-2" />
            <span class="fs-16">{props.sqft} Sqft</span>
          </li>
        </ul>
        <div class="pl-footer top-border d-flex align-items-center justify-content-between">
          <strong class="price fw-500 color-dark">Ksh{numberWithCommas(props.price)}</strong>
        </div>
      </div>
    </div>



  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  photo_main: PropTypes.string.isRequired,
  photo_1: PropTypes.string,
  photo_2: PropTypes.string,
  photo_3: PropTypes.string,
  photo_4: PropTypes.string,
  photo_5: PropTypes.string,
  photo_6: PropTypes.string,
  photo_7: PropTypes.string,
  photo_8: PropTypes.string,
  photo_9: PropTypes.string,
  photo_10: PropTypes.string,
  photo_11: PropTypes.string,
  photo_12: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  bedrooms: PropTypes.number,
  bathrooms: PropTypes.string,
  sale_type: PropTypes.string.isRequired,
  property_type: PropTypes.string.isRequired,
  sqft: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
};

export default Card;
