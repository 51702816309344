import React from "react";
import ils_08 from "../assets/images/assets/ils_08.svg";
import title_shape from "../assets/images/shape/title_shape_02.svg"

const notFound = () => (
  <div className="error-section position-relative z-1 bg-green-one">
    <div className="container">
      <div className="row">
        <div className="col-xxl-8 col-xl-6 col-lg-7 col-md-8 m-auto">
          <div className="title-one text-center mb-75 lg-mb-20 wow fadeInUp">
            <h3>
              <span>
                Oops!{" "}
                <img
                  src={title_shape}
                  alt=""
                  className="lazy-img"
                />
              </span>
              It looks like you're lost.
            </h3>
            <p className="fs-20 pb-45">
              The page you're looking for isn't available. Try to search again
              or use the go to
            </p>
            <a href="/" className="btn-five sm fw-normal text-uppercase">
              <i className="fa-regular fa-house fa-beat-fade"></i>&nbsp;&nbsp;
              <strong>Go to home</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
    <img
      src={ils_08}
      alt=""
      className="lazy-img w-100 position-absolute bottom-0 start-0 z-n1"
    />
  </div>
);

export default notFound;
