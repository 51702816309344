import React from "react";
import { Link } from "react-router-dom";
import ils_07 from '../assets/images/assets/ils_07.svg';
import shape_37 from '../assets/images/shape/shape_37.svg';
import screen_10 from '../assets/images/media/sold.png';
import aboutimg from '../assets/images/media/aboutimg.png';
import icon_23 from '../assets/images/icon/icon_23.svg';
import icon_24 from '../assets/images/icon/icon_24.svg';
import icon_25 from '../assets/images/icon/icon_25.svg';
import shape_57 from '../assets/images/shape/shape_51.svg';
import realtor_agent from '../assets/images/media/realtor_agent.png';
import shape_71 from '../assets/images/shape/shape_71.svg';
import shape_39 from '../assets/images/shape/shape_39.svg';
import icon_14 from '../assets/images/icon/icon_40.svg';
import icon_41 from '../assets/images/icon/icon_41.svg';
import icon_42 from '../assets/images/icon/icon_42.svg';
import img_50 from '../assets/images/shape/shape_50.svg';
import lazy from '../assets/images/shape/lazy.svg';
import title_shape_02 from '../assets/images/shape/title_shape_02.svg';

const about = () =>
	<div class="main-page-wrapper">
		<div class="inner-banner-one inner-banner bg-pink text-center z-1 pt-160 lg-pt-130 pb-160 xl-pb-120 md-pb-80 position-relative">
			<div class="container">
				<div class="title-one text-center pt-40 mb-10 xl-mb-10 lg-mb-10 wow fadeInUp">
					<h3 class="mb-35 xl-mb-20 pt-15">About <span>Agency <img src={title_shape_02} data-src={lazy} alt="" class="lazy-img"></img></span></h3>
					<ul class="theme-breadcrumb style-none d-inline-flex align-items-center justify-content-center position-relative z-1 bottom-line">
						<li><Link to="/">Home</Link></li>
						<li>/</li>
						<li>About Agency</li>
					</ul>
				</div>
			</div>
			<img src={ils_07} alt="" class="lazy-img shapes w-100 illustration" />
		</div>

		<div class="block-feature-fifteen mt-150 xl-mt-100 mb-140 xl-mb-80">
			<div class="container">
				<div class="row gx-xl-5">
					<div class="col-xl-6 col-lg-7 order-lg-last wow fadeInRight">
						<div class="ms-xxl-5 ps-xl-4 ps-lg-5 md-mb-50">
							<div class="title-one mb-45 lg-mb-20">
								<h2 class="font-garamond star-shape">Find Your Preferable Match Easily. <span class="star-shape"><img src={shape_37} alt="" class="lazy-img" /></span></h2>
							</div>
							<div class="accordion-style-three">
								<div class="accordion" id="accordionThree">
									<div class="accordion-item">
										<h2 class="accordion-header">
											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneA" aria-expanded="false" aria-controls="collapseOneA">
												Who we are?
											</button>
										</h2>
										<div id="collapseOneA" class="accordion-collapse collapse show" data-bs-parent="#accordionThree">
											<div class="accordion-body">
												<p class="fs-22">Our founders Dustin Moskovitz and Justin Rosenstein met while leading Engineering .</p>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoA" aria-expanded="false" aria-controls="collapseTwoA">
												What’s our goal
											</button>
										</h2>
										<div id="collapseTwoA" class="accordion-collapse collapse" data-bs-parent="#accordionThree">
											<div class="accordion-body">
												<p class="fs-22">Our founders Dustin Moskovitz and Justin Rosenstein met while leading Engineering .</p>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeA" aria-expanded="true" aria-controls="collapseThreeA">
												Our vision
											</button>
										</h2>
										<div id="collapseThreeA" class="accordion-collapse collapse" data-bs-parent="#accordionThree">
											<div class="accordion-body">
												<p class="fs-22">Our founders Dustin Moskovitz and Justin Rosenstein met while leading Engineering .</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<Link to="/" class="btn-five mt-75 lg-mt-50">Contact us</Link>
						</div>
					</div>
					<div class="col-xl-6 col-lg-5 d-lg-flex wow fadeInLeft">
						<div class="media-block h-100 w-100 pe-xl-5">
							<div class="bg-img position-relative" style={{ backgroundImage: `url(${aboutimg})` }}>
								<img src={screen_10} alt="" class="lazy-img shapes screen_01" />
							</div>
						</div>
					</div>
				</div>
				<div class="wrapper mt-90 lg-mt-40">
					<div class="row justify-content-center">
						<div class="col-md-4 col-sm-6">
							<div class="counter-block-two text-center dark mt-30">
								<div class="main-count sm font-garamond fw-500"><span class="counter">720</span>k+</div>
								<p class="fs-20 mt-15 md-mt-10">Project handover</p>
							</div>
						</div>
						<div class="col-md-4 col-sm-6">
							<div class="counter-block-two text-center dark mt-30">
								<div class="main-count sm font-garamond fw-500"><span class="counter">1.3</span>%</div>
								<p class="fs-20 mt-15 md-mt-10">Low Interest</p>
							</div>
						</div>
						<div class="col-md-4 col-sm-6">
							<div class="counter-block-two text-center dark mt-30">
								<div class="main-count sm font-garamond fw-500"><span class="counter">1.9</span>mil+</div>
								<p class="fs-20 mt-15 md-mt-10">Happy customers</p>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="block-feature-sixteen">
			<div class="bg-pink-two position-relative z-1 pt-140 xl-pt-100 lg-pt-80 pb-150 xl-pb-120 lg-pb-100">
				<div class="container">
					<div class="title-one text-center mb-70 xl-mb-40 lg-mb-20">
						<h2 class="font-garamond star-shape"><span class="star-shape"><img src={shape_37} alt="" class="lazy-img" /></span> Buy, Rend & Sell</h2>
						<p class="fs-22 mt-xs color-dark">Over 745K listings of apartments, lots, plots - available today.</p>
					</div>
					<div class="row justify-content-center gx-xxl-5">
						<div class="col-lg-4 col-md-6 mt-30 d-flex wow fadeInUp">
							<div class="card-style-five text-center d-inline-flex flex-column align-items-center tran3s h-100 w-100">
								<img src={icon_23} alt="" class="lazy-img icon" />
								<h5 class="mt-35 mb-20">Buy a home</h5>
								<p class="fs-22 mb-50">Explore homy’s 2 million+ homes and uncover your ideal living space.</p>
								<a href="listing_10.html" class="btn-twelve mt-auto">Find Home</a>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 mt-30 d-flex wow fadeInUp" data-wow-delay="0.1s">
							<div class="card-style-five text-center d-inline-flex flex-column align-items-center tran3s h-100 w-100">
								<img src={icon_24} alt="" class="lazy-img icon" />
								<h5 class="mt-35 mb-20">Rent a home</h5>
								<p class="fs-22 mb-50">Discover a rental you'll love on homy, thanks to 35+ filters and tailored keywords.</p>
								<a href="listing_10.html" class="btn-twelve mt-auto">Rent Home</a>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 mt-30 d-flex wow fadeInUp" data-wow-delay="0.2s">
							<div class="card-style-five text-center d-inline-flex flex-column align-items-center tran3s h-100 w-100">
								<img src={icon_25} alt="" class="lazy-img icon" />
								<h5 class="mt-35 mb-20">Sell property</h5>
								<p class="fs-22 mb-50">List, sell, thrive – with our top-notch real estate agency. It’s super easy & fun.</p>
								<a href="listing_10.html" class="btn-twelve mt-auto">Sell Property</a>
							</div>
						</div>
					</div>
				</div>

				<img src={shape_71} alt="" class="lazy-img shapes shape_01 wow fadeInDown" />
				<img src={shape_39} alt="" class="lazy-img shapes shape_02 wow fadeInUp" />
			</div>
			<div class="block-feature-fourteen pt-150 xl-pt-120 lg-pt-100 pb-150 xl-pb-120 lg-pb-80">
				<div class="container">
					<div class="row">
						<div class="col-lg-6">
							<div class="title-one md-mb-30 pe-xxl-4 wow fadeInLeft">
								<h2 class="font-garamond text-white star-shape">Easily Purchase, Sell, or Lease listings. <span class="star-shape"><img src={shape_37} alt="" class="lazy-img" /></span></h2>
							</div>
						</div>
						<div class="col-xxl-5 col-lg-6 ms-auto">
							<p class="text-white fs-24 m0 lh-lg  wow fadeInRight">Your leading real estate advocate, transforming houses into dreams. Trust us to expertly guide you home</p>
						</div>
					</div>

					<div class="wow fadeInUp mt-70 xl-mt-50">
						<div class="row">
							<div class="col-lg-4">
								<div class="card-style-eight mt-45 wow fadeInUp">
									<div class="d-flex pe-xxl-5">
										<img src={icon_14} alt="" class="lazy-img icon" />
										<div class="text">
											<h5 class="text-white">Property Insurance</h5>
											<p>Elit esse cillum dol fug nulla tur nos ullamo.</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="card-style-eight mt-45 wow fadeInUp">
									<div class="d-flex pe-xxl-2 ps-xxl-2">
										<img src={icon_41} alt="" class="lazy-img icon" />
										<div class="text">
											<h5 class="text-white">Easy Payments</h5>
											<p>quis nostrud exerct ulla security finibus ne derived.</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4">
								<div class="card-style-eight mt-45 wow fadeInUp">
									<div class="d-flex ps-xxl-5">
										<img src={icon_42} alt="" class="lazy-img icon" />
										<div class="text">
											<h5 class="text-white">Quick Process</h5>
											<p>Duis aute irure do reprehe de Cicero's voluptat velit.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="fancy-banner-eight wow fadeInUp mt-160 xl-mt-100 mb-120 xl-mb-100 lg-mb-80">
			<div class="container container-large">
				<div class="bg-wrapper border-30 bg-pink-two overflow-hidden position-relative z-1">
					<div class="row align-items-end">
						<div class="col-xl-6 col-lg-7 col-md-7">
							<div class="pb-80 lg-pb-40">
								<h3>Start your Journey as <span class="fw-normal fst-italic">A Retailer.</span></h3>
								<div class="d-inline-flex flex-wrap align-items-center position-relative mt-15">
									<a href="/contact" class="btn-two rounded-0 border-0 mt-10"><span>Contact us</span></a>
									<img src={shape_57} alt="" class="lazy-img shapes shape_02 wow fadeInRight" />
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-lg-5 col-md-5 text-center text-md-end">
							<div class="media-wrapper position-relative z-1 d-inline-block">
								<img src={realtor_agent} alt="" class="lazy-img" />
								<img src={img_50} alt="" class="lazy-img shapes shape_01" />
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div>;

export default about;
