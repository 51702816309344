import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

function Transition(props) {
  return <Slide {...props} direction="down" />;
}

const AlertComponent = ({ alerts }) => {
    if (alerts !== null && alerts.length > 0) {
      window.scrollTo(0, 0);
    }
  
    return alerts !== null && alerts.length > 0 && alerts.map(alert => (
      <Snackbar
        open={true}
        TransitionComponent={Transition}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant='filled'
          icon={
            alert.alertType === 'success' ? <CheckIcon fontSize="inherit" /> :
            alert.alertType === 'error' ? <ErrorIcon fontSize="inherit" /> :
            alert.alertType === 'info' ? <InfoIcon fontSize="inherit" /> :
            alert.alertType === 'warning' ? <WarningIcon fontSize="inherit" /> :
            null
          }
          key={alert.id}
          severity={alert.alertType}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
    ));
  };

AlertComponent.propTypes = {
    alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    alerts: state.alert
});

export default connect(mapStateToProps)(AlertComponent);