import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { openModal } from '../actions/auth';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import FancyboxListing from '../components/FancyboxListing';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import ils_07 from '../assets/images/assets/ils_07.svg';
import shape_57 from '../assets/images/shape/shape_51.svg';
import img_50 from '../assets/images/shape/shape_50.svg';
import realtor_agent from '../assets/images/media/realtor_agent.png';

const ProjectDetails = () => {
    const [project, setProject] = useState(null);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const handleLoginClick = () => {dispatch(openModal());};
    const formatNumberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    
      
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchProject = async () => {
            try {
                const config = {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                };

                const res = await axios.get(`${'https://www.pevikholdings.co.ke'}/api/projects/${id}`, config);
                setProject(res.data);
                setIsLoading(false);
            }
            catch (err) {
                if (err.response && err.response.status === 401) {
                    setError("Please log in to view project.");
                } else {
                    setError("Or an error occurred.");
                }
                setIsLoading(false);
            }
        };

        fetchProject();
    }, [id]);
    

    if (isLoading) {
        return (
            <div className="main-page-wrapper">
                <div class="inner-banner-two inner-banner z-1 pt-160 lg-pt-130 pb-160 xl-pb-120 md-pb-80 position-relative" style={{ backgroundImage: `url(${ils_07})` }}>
                    <div class="container">
                        <div class="row pt-40">
                            <div class="col-lg-6">
                                <h3 class="mb-35 xl-mb-20">Single Project</h3>
                                <ul class="theme-breadcrumb style-none d-inline-flex align-items-center justify-content-center position-relative z-1 bottom-line">
                                    <li><Link to='/'>Home</Link></li>
                                    <li>/</li>
                                    <li><Link to='/projects'>Project</Link></li>
                                    <li>/</li>
                                    <li>_______</li>
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <p class="sub-heading">New listings every day, apartments, offices and plots available now!</p>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="listing-details-one theme-details-one bg-green-one pt-180 lg-pt-150 pb-150 xl-pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="bg-white shadow4 border-20">
                                    <div className='pt-10'>
                                    <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                        <center>
                                            <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={650} height={518} margin={20} />
                                        </center>
                                    </Stack>
                                    </div>
                                    <div className='pt-10'>
                                    <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                        <center>
                                            <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={650} height={518} margin={20} />
                                        </center>
                                    </Stack>
                                    </div>
                                    <div className='pt-10'>
                                    <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                        <center>
                                            <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={650} height={518} margin={20} />
                                        </center>
                                    </Stack>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-xl-6 col-lg-8 me-auto ms-auto">
                                <div className="theme-sidebar-one ms-xl-5 lg-mt-80">
                                    <div className="tour-schedule bg-white border-20 p-30 mb-40">
                                        <div className='pt-10'>
                                            <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                                <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={90} height={20} margin={20} />
                                            </Stack>
                                        </div>
                                        <div className='pt-10'>
                                            <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                                <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={600} height={150} margin={20} />
                                            </Stack>
                                        </div>
                                        <div className='pt-20'>
                                            <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                                <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={600} height={50} margin={20} />
                                            </Stack>
                                        </div>
                                        <div className='pt-20'>
                                            <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                                <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={300} height={50} margin={20} />
                                            </Stack>
                                        </div>
                                        <div className='pt-20'>
                                            <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                                <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={600} height={400} margin={20} />
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="main-page-wrapper bg-green-one">
        <div className="pt-110"></div>
        <div class="fancy-banner-eight wow fadeInUp mt-160 xl-mt-100 mb-120 xl-mb-100 lg-mb-80">
        <div class="container container-large">
            <div class="bg-wrapper border-30 bg-pink-two overflow-hidden position-relative z-1">
                <div class="row align-items-end">
                    <div class="col-xl-8 col-lg-7 col-md-7">
                        <div class="pb-80 lg-pb-40">
                            <h3>You are not authorized, <span class="fw-normal fst-italic">{error}</span></h3>
                            <div class="d-inline-flex flex-wrap align-items-center position-relative mt-15">
                                <Link onClick={handleLoginClick} class="btn-two rounded-0 border-0 mt-10"><span>Log In</span></Link>
                                <img src={shape_57} alt="" class="lazy-img shapes shape_02 wow fadeInRight" />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5 col-md-5 text-center text-md-end">
							<div class="media-wrapper position-relative z-1 d-inline-block">
								<img src={realtor_agent} alt="" class="lazy-img" />
								<img src={img_50} alt="" class="lazy-img shapes shape_01" />
							</div>
						</div>
                </div>
                
            </div>
        </div>
        </div>
    </div>;
    }

    if (!project) {
        return<div className="main-page-wrapper">
        <div className="pt-110"></div>
            <div className="listing-details-one theme-details-one bg-green-one pt-180 lg-pt-150 pb-150 xl-pb-120">
                <div className="container">
                    <h5>Project not found.</h5>
                </div>
            </div>
        </div>;
        
        
    }

    return (
        <div>
            <Helmet>
                <title>Pevik Holdings - {project.title}</title>
            </Helmet>
            <div class="main-page-wrapper bg-green-one">
                <div class="inner-banner-two inner-banner z-1 pt-160 lg-pt-130 pb-160 xl-pb-120 md-pb-80 position-relative" style={{ backgroundImage: `url(${ils_07})` }}>
                    <div class="container">
                        <div class="row pt-40">
                            <div class="col-lg-6">
                                <h3 class="mb-35 xl-mb-20">{project.project_type}</h3>
                                <ul class="theme-breadcrumb style-none d-inline-flex align-items-center justify-content-center position-relative z-1 bottom-line">
                                    <li><Link to='/'>Home</Link></li>
                                    <li>/</li>
                                    <li><Link to='/projects'>Project</Link></li>
                                    <li>/</li>
                                    <li>{project.title}</li>
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <p class="sub-heading">New listings every day, apartments, offices and plots available now!</p>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="project-details-one mt-150 xl-mt-100 mb-170 xl-mb-100">
                <div class="container">
                    <div class="row gx-xxl-5">
                        <div class="col-lg-6 order-lg-first">
                            <figure class="image-wrapper">
                                <img src={project.photo_1} alt="" class="lazy-img w-100"  />
                            </figure>
                            <figure class="image-wrapper">
                                <img src={project.photo_2} alt="" class="lazy-img w-100"  />
                            </figure>
                            <figure class="image-wrapper">
                                <img src={project.photo_3} alt="" class="lazy-img w-100" />
                            </figure>
                        </div>
                        <div class="col-lg-6">
                            <div class="details-text ps-xxl-5 md-mt-40">
                                <div class="tag fw-500 text-uppercase">{project.project_type}</div>
                                <h3>{project.title}</h3>
                                <p class="fs-24 pt-45 xl-pt-30 pb-45 xl-pb-30">Your leading real estate advocate, transforming houses into dreams. </p>
                                <h4 class="mb-40">Project Details</h4>
                                <div class="project-info-outline">
                                    <div class="main-bg">
                                        <ul class="style-none">
                                            <li class="position-relative z-1">
                                                <div class="num fw-light">01</div>
                                                <i class="fa-solid fa-calendar-days icon"></i>
                                                <strong>Date</strong>
                                                <span>{project.list_date}</span>
                                            </li>
                                            <li class="position-relative z-1">
                                                <div class="num fw-light">02</div>
                                                <i class="fa-solid fa-ballot-check icon"></i>
                                                <strong>Project Type</strong>
                                                <span>{project.project_type}</span>
                                            </li>
                                            <li class="position-relative z-1">
                                                <div class="num fw-light">03</div>
                                                <i class="fa-sharp fa-solid fa-building icon"></i>
                                                <strong>No Of Units</strong>
                                                <span>{project.units}</span>
                                            </li>
                                            <li class="position-relative z-1">
                                                <div class="num fw-light">04</div>
                                                <i class="fa-sharp fa-solid fa-building icon"></i>
                                                <strong>Price</strong>
                                                <span><b>Ksh</b>&nbsp;{formatNumberWithCommas(project.price)}&nbsp;&nbsp;<b><i class="fa-solid fa-arrows-from-line fa-rotate-90"></i></b>&nbsp;&nbsp;<b>Ksh</b>&nbsp;{formatNumberWithCommas(project.price)}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                

                                <div class="page-pagination mt-45 pt-50">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center page-title position-relative">
                                            <img src={project.project_logo} alt="" class="lazy-img img" />
                                            <div className="ps-4">
                                                <span>Call Versailles garden</span>
                                                <Link to='/'>
                                                    <h5 className="m0"><i class="fa-solid fa-arrow-turn-down-right"></i>&nbsp;&nbsp;0706641895</h5>
                                                </Link>
                                            </div>
                                        </div>
                                        <Link href="#" class="btn-four rounded-circle inverse"><i class="fa-solid fa-phone-volume icon"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="project-details-one bg-green-one mt-100 xl-mt-100 mb-170 xl-mb-100">
                <div class="container">
                    <div class="row gx-xxl-5 pt-50 shadow4 border-top p-40">
                            <div class="col-lg-6 order-lg-first">
                                <h4 class="mb-30">Project Overview</h4>
                                {ReactHtmlParser(project.description)}
                            </div>
                            <div class="col-lg-6">
                                <figure class="image-wrapper">
                                    <img src={project.photo_1} alt="" class="lazy-img w-100" width={640} height={420} />
                                </figure>
                                <figure class="image-wrapper">
                                    <img src={project.photo_4} alt="" class="lazy-img w-100" width={640} height={420} />
                                </figure>
                                <figure class="image-wrapper">
                                    <img src={project.photo_5} alt="" class="lazy-img w-100" width={640} height={420} />
                                </figure>
                            </div>
                    
                        <div class="listing-details-one theme-details-one lg-pt-150 pb-30 xl-pb-120">
                            <div class="bg-light">
                                <div class="property-video-tour border-top p-40">
                                    <h4 class="mb-30">Video Tour</h4>
                                    <div class="position-relative border-15 image-bg overflow-hidden z-1">
                                        <img src={project.photo_4} alt=""
                                            class="lazy-img w-100" width={1000} height={744} />
                                        <FancyboxListing
                                            options={{
                                                Carousel: {
                                                    infinite: false,
                                                },
                                            }}
                                        >
                                            <a class="video-icon tran3s rounded-circle d-flex align-items-center justify-content-center"
                                                data-fancybox href={project.video_url} width="895px" height="518px" data-caption={project.title}>
                                                <i class="fa-thin fa-play"></i>
                                            </a>
                                        </FancyboxListing>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetails;