import React from 'react';
import { Link } from 'react-router-dom';
import Items from "../components/Items";
import FeaturedProjects from '../components/FeturedProjects';
import Fancybox from "../components/Fancybox";
import BasicSearch from '../components/BasicSearch';
import NewsletterForm from '../components/NewsletterForm';
import shape_11 from '../assets/images/shape/shape_11.svg';
import banner from '../assets/images/assets/ils_banner.webp';
import shape_12 from '../assets/images/shape/shape_12.svg';
import bage_01 from '../assets/images/assets/badge_01.svg';
import shape_22 from '../assets/images/shape/shape_22.svg';
import icon_11 from '../assets/images/icon/icon_11.svg';
import icon_12 from '../assets/images/icon/icon_12.svg';
import icon_13 from '../assets/images/icon/icon_13.svg';
import shape_38 from '../assets/images/shape/shape_38.svg';
import shape_39 from '../assets/images/shape/shape_39.svg';
import icon_07 from '../assets/images/icon/icon_07.svg';
import icon_08 from '../assets/images/icon/icon_08.svg';
import icon_09 from '../assets/images/icon/icon_09.svg';
import shape_07 from '../assets/images/shape/shape_07.svg';
import shape_08 from '../assets/images/shape/shape_08.svg';
//import shape_44 from '../assets/images/shape/shape_44.svg';
//import shape_45 from '../assets/images/shape/shape_45.svg';
import shape_088 from '../assets/images/shape/title_shape_08.svg';
import ils from '../assets/images/assets/ils_02.svg';
import shape_37 from '../assets/images/shape/shape_37.svg';
//import fancybg1 from '../assets/images/media/fancy_bg.png';


const Home = () => {

    return (
        <div class="main-page-wrapper">
            <div class="hero-banner-two z-1 position-relative">
                <div class="container">
                    <div class="position-relative line-bg pt-200 md-pt-150 pb-130 xl-pb-100">
                        <div class="row">
                            <div class="col-xxl-9 col-xl-8 col-lg-9 col-md-10">
                                <h1 class="hero-heading font-garamond wow fadeInUp">Affordable Plots and homes for you <span><img
                                    src={shape_11} alt=""
                                    class="lazy-img icon d-inline-block" /></span></h1>
                                <p class="fs-24 color-dark pt-35 md-pt-30 pb-35 mb-pb-20 wow fadeInUp" data-wow-delay="0.1s">We’ve
                                    apartments, plots & offices.</p>
                                <div class="d-inline-flex align-items-center flex-wrap">
                                    <Link to="/listings" class="btn-seven mb-20 me-4 me-xxl5"><span>Explore All listing</span>
                                        <i class="bi bi-arrow-up-right"></i></Link>
                                    <Fancybox
                                        options={{
                                            Carousel: {
                                                infinite: false,
                                            },
                                        }}
                                    >
                                        <a class="video-icon tran3s d-flex align-items-center justify-content-center mb-20" data-fancybox href="https://www.youtube.com/embed/aXFSJTjVjw0" data-caption='Pevik Holdings Ltd' >
                                            <i class="fa-light fa-play"></i>
                                            <div class="ps-3 text-start">
                                                <span class="d-block">Watch</span>
                                                <strong class="fs-20 color-dark fw-normal d-block">Intro video</strong>
                                            </div>
                                        </a>

                                    </Fancybox>

                                </div>
                            </div>
                        </div>
                        <div class="search-wrapper-one layout-two mt-60 lg-mt-40 position-relative">
                            <nav class="search-filter-nav-one d-flex">
                                <div class="nav nav-tabs border-0" role="tablist">
                                    <button class="nav-link active" id="buy-tab" data-bs-toggle="tab" data-bs-target="#buy"
                                        type="button" role="tab" aria-controls="buy" aria-selected="true">Buy</button>
                                    <button class="nav-link" id="rent-tab" data-bs-toggle="tab" data-bs-target="#rent" type="button"
                                        role="tab" aria-controls="rent" aria-selected="false">Rent</button>
                                </div>
                            </nav>
                            <div class="bg-wrapper border-0 rounded-0">
                                <div class="tab-content">
                                    <div class="tab-pane show active" id="buy" role="tabpanel" aria-labelledby="buy-tab" tabindex="0">
                                        <BasicSearch />
                                    </div>
                                    <div class="tab-pane" id="rent" role="tabpanel" aria-labelledby="rent-tab" tabindex="0">
                                        <BasicSearch />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={banner} alt="" class="lazy-img shapes illustration" />
                <img src={shape_12} alt="" class="lazy-img shapes shape_01" />
                <img src={bage_01} alt="" class="lazy-img shapes shape_02" />
            </div>

            <div class="block-feature-seven position-relative z-1 mt-150 xl-mt-120">
                <div class="container">
                    <div class="position-relative">
                        {/*<div class="text-center wow fadeInUp">
                            <div class="title-one mb-30 lg-mb-20">
                                <h2 class="font-garamond">How our clients get <i>benefited</i> by us</h2>
                                <p class="fs-24 mt-xs">“Outstanding service, bought my dream home quickly!”</p>
                            </div>

                            <ul class="rating style-none d-flex justify-content-center">
                                <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                <li><i class="fa-sharp fa-solid fa-star"></i></li>
                                <li><span class="color-dark fw-500">4.7</span> (Reviews)</li>
                            </ul>
                        </div>

                        <div class="wrapper position-relative z-1 mt-45 lg-mt-20 mb-100 lg-mb-50">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 wow fadeInUp">
                                    <div class="card-style-two overflow-hidden position-relative z-1 mt-30">
                                        <img src="assets/images/media/plot_sale.png" alt=""
                                            class="lazy-img w-100 tran5s" />
                                        <div class="content text-center">
                                            <h5 class="mb-25">Explore & Buy Plot</h5>
                                            <div class="btn tran3s fw-500 text-uppercase">BUY PLOT</div>
                                            <Link to="/" class="stretched-link"></Link>
                                        </div>

                                    </div>

                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card-style-two overflow-hidden position-relative z-1 mt-30">
                                        <img src="assets/images/media/home_sale.png" alt=""
                                            class="lazy-img w-100 tran5s" />
                                        <div class="content text-center">
                                            <h5 class="mb-25">Explore & Buy Home</h5>
                                            <div class="btn tran3s fw-500 text-uppercase">BUY HOME</div>
                                            <Link to="/" class="stretched-link"></Link>
                                        </div>

                                    </div>

                                </div>
                                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                                    <div class="card-style-two overflow-hidden position-relative z-1 mt-30">
                                        <img src="assets/images/media/office_rent.png" alt=""
                                            class="lazy-img w-100 tran5s" />
                                        <div class="content text-center">
                                            <h5 class="mb-25">Discover & Get Rental</h5>
                                            <div class="btn tran3s fw-500 text-uppercase">RENT NOW</div>
                                            <Link to="/" class="stretched-link"></Link>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <img src="assets/images/shape/shape_21.svg" alt="" class="lazy-img shapes shape_01" />
                        </div>*/}


                        <div class="row align-items-center mb-100 lg-mb-50 md-mb-20">
                            <div class="col-xxl-6 col-xl-5 col-lg-8">
                                <div class="title-one">
                                    <h2 class="font-garamond m0 lh-1 position-relative">W’er here to help you find properties. <img
                                        src={shape_22} alt=""
                                        class="lazy-img shapes shape_02" /></h2>
                                </div>

                            </div>
                            <div class="col-xxl-4 col-xl-4">
                                <p class="fs-24 m0 color-dark lg-pt-30 lg-pb-50">We’v big collection of property & apartments, home for sell & rent</p>
                            </div>
                            <div class="col-xxl-2 col-xl-3 d-inline-flex justify-content-xl-end">
                                <a href='/listings' class="btn-five text-uppercase rounded-0">Get Started</a>
                            </div>
                        </div>

                        <div class="row gx-lg-5 align-items-center">
                            <div class="col-lg-3">
                                <div class="card-style-three mt-40 wow fadeInUp">
                                    <div class="bg-wrapper text-center">
                                        <h3>04+</h3>
                                        <p>Years Experience <br /></p>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-9">
                                <div class="row gx-xl-5">
                                    <div class="col-md-4">
                                        <div class="card-style-four mt-40 wow fadeInUp">
                                            <div
                                                class="icon rounded-circle d-flex align-items-center justify-content-center position-relative">
                                                <img src={icon_11} alt=""
                                                    class="lazy-img" />
                                            </div>
                                            <h5 class="mt-35 mb-15">Property Insurance</h5>
                                            <p>Elit esse cillum dolo fugiat nulla tur nos ullamo.</p>
                                        </div>

                                    </div>
                                    <div class="col-md-4">
                                        <div class="card-style-four mt-40 wow fadeInUp">
                                            <div
                                                class="icon rounded-circle d-flex align-items-center justify-content-center position-relative">
                                                <img src={icon_12} alt=""
                                                    class="lazy-img" />
                                            </div>
                                            <h5 class="mt-35 mb-15">Easy Payments</h5>
                                            <p>quis nostrud exerct ullamo sucirity finibus ne derived.</p>
                                        </div>

                                    </div>
                                    <div class="col-md-4">
                                        <div class="card-style-four mt-40 wow fadeInUp">
                                            <div
                                                class="icon rounded-circle d-flex align-items-center justify-content-center position-relative">
                                                <img src={icon_13} alt=""
                                                    class="lazy-img" />
                                            </div>
                                            <h5 class="mt-35 mb-15">Quick Process</h5>
                                            <p>Duis aute irure dolor reprehe de Cicero's voluptat velit.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="property-listing-three position-relative z-1 mt-170 xl-mt-140 lg-mt-120">
                <div class="container container-large">
                    <div class="position-relative">
                        <div class="title-one mb-60 lg-mb-40 wow fadeInUp">
                            <h2 class="font-garamond star-shape">Featured <em>Projects</em> <span class="star-shape"><img src={shape_37} alt="" class="lazy-img" /></span>
                            </h2>
                            <p class="fs-22 m0">Explore featured properties for sale.</p>
                        </div>
                        <FeaturedProjects />
                        <div class="section-btn text-center md-mt-60">
                            <Link to='/projects' class="btn-five md"><span>Explore All</span> <i class="bi bi-arrow-up-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="property-listing-four bg-pink-three position-relative z-1 overflow-hidden pt-120 xl-pt-80 pb-110 xl-pb-60 mt-170 xl-mt-140 lg-mt-120">
                <div class="container container-large">
                    <div class="position-relative z-1">
                        <div class="title-one mb-60 lg-mb-40 wow fadeInUp">
                            <h2 class="font-garamond">New <em>Listings</em> <span class="star-shape"><img src={shape_37} alt="" class="lazy-img" /></span></h2>
                            <p class="fs-22 m0">Explore latest & featured properties for sale.</p>
                        </div>
                        <div class="listing-slider-one">
                            <div class="item">
                                
                                    <Items />
                                
                            </div>
                            </div>
                            <div class="section-btn text-center">
                                <Link to="/listings" class="btn-eleven fst-italic"><span>See all properties&nbsp;&nbsp;<i class="fa-duotone fa-arrow-up-right-from-square fa-bounce"></i></span></Link>
                            </div>
                        </div>
                    </div>

                    <img src={shape_38} alt="" class="lazy-img shapes shape_01 wow fadeInDown" />
                    <img src={shape_39} alt="" class="lazy-img shapes shape_02 wow fadeInUp" />
                </div>
                <div class="block-feature-five position-relative z-1 bg-green-one pt-100 lg-pt-80 pb-130 xl-pb-100 lg-pb-80 mt-225 xl-mt-200 lg-mt-150">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-8 m-auto">
                                <div class="title-one text-center mb-35 lg-mb-20">
                                    <h3>We’r here to help <br /> you get your <span>dream property<img src={shape_07} alt="" class="lazy-img" /></span></h3>
                                    <p class="fs-24 color-dark">It’s easy to start with us with these simple steps</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-between">
                            <div class="col-xxl-11 m-auto">
                                <div class="row gx-xl-5 justify-content-center">
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="card-style-one text-center wow fadeInUp mt-40">
                                            <img src={icon_07} alt="" class="lazy-img m-auto icon" />
                                            <h5 class="mt-50 lg-mt-30 mb-15">Create Account</h5>
                                            <p class="pe-xxl-4 ps-xxl-4">It’s very easy to open an account and start your journey.</p>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="card-style-one text-center wow fadeInUp mt-40 arrow position-relative" data-wow-delay="0.1s">
                                            <img src={icon_08} alt="" class="lazy-img m-auto icon" />
                                            <h5 class="mt-50 lg-mt-30 mb-15">Find Property</h5>
                                            <p class="pe-xxl-4 ps-xxl-4">Complete your profile with all the info to get attention of client.</p>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="card-style-one text-center wow fadeInUp mt-40" data-wow-delay="0.2s">
                                            <img src={icon_09} alt="" class="lazy-img m-auto icon" />
                                            <h5 class="mt-50 lg-mt-30 mb-15">Quick Process</h5>
                                            <p class="pe-xxl-4 ps-xxl-4">Apply & get your preferable jobs with all the requirements and get it.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={shape_07} alt="" class="lazy-img shapes shape_01" />
                    <img src={shape_08} alt="" class="lazy-img shapes shape_02" />
                </div>

                {/*<div class="fancy-banner-seven mt-110 xl-mt-80 mb-130 xl-mb-100 lg-mb-80">
                <div class="container container-large">
                    <div class="bg-wrapper position-relative z-1 pt-85 lg-pt-70 pb-100 xl-pb-80 lg-pb-70" style={{ backgroundImage: `url(${fancybg1})` }}>
                        <div class="row">
                            <div class="col-xl-7 col-lg-8 col-md-10 m-auto text-center">
                                <h2 class="font-garamond text-white">Find the right home for your family</h2>
                                <p class="fs-24 text-white mt-30 mb-45 lg-mb-30">We’ve apartments, Homes & plot.</p>

                                <a href='/' data-bs-toggle="modal" data-bs-target="#loginModal" class="btn-two"><span>Get Started</span></a>
                            </div>
                        </div>
                        <img src={shape_44} alt="" class="lazy-img shapes shape_01 wow fadeInLeft" data-wow-delay="0.2s" />
                        <img src={shape_45} alt="" class="lazy-img shapes shape_02 wow fadeInRight" />
                    </div>
                </div>
                </div>*/}

                <div class="fancy-banner-three position-relative text-center z-1 pt-200 xl-pt-150 lg-pt-100 pb-250 xl-pb-200 lg-pb-120">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-7 col-md-8 m-auto">
                                <div class="title-one mb-45 md-mb-30">
                                    <h2>Any Inquiry? <span>Feel free<img src={shape_088} alt="" class="lazy-img" /></span> To contact Us.</h2>
                                </div>
                                <Link to='/contact' class="btn-five text-uppercase">SEND MESSAGE</Link>
                            </div>
                        </div>
                    </div>
                    <img src={ils} alt="" class="lazy-img shapes w-100 illustration" />
                </div>
                <NewsletterForm />
            </div>

            );
};

            export default Home;
