import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from '../actions/auth';

const AuthenticateLink = (props) => {

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const dispatch = useDispatch();
  
    const handleClick = (event) => {
      if (!isAuthenticated) {
        event.preventDefault();
        dispatch(openModal()); // Dispatch action to open the modal
      }
    };

    return (
        <Link to={props.to} className={props.className} onClick={handleClick}>
            {props.children}
        </Link>
    );
};

export default AuthenticateLink;