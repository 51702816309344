import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';

const BasicSearch = () => {
    const [category, setCategory] = useState('');
    const [saleType, setSaleType] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [keywords, setKeywords] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${'https://www.pevikholdings.co.ke'}/api/listings/search`, {
                category, saleType, minPrice, keywords
            });
            navigate(`/property-type`, { state: { searchResults: response.data } });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
            setError('An error occurred while fetching data, try again');
        }
    };

    return (
        <form className='listingform' onSubmit={handleSearch}>
            <div className="row gx-0 align-items-center">
                <div className="col-xl-2 col-md-6">
                    <div className="input-box-one border-left">
                        <div className="label">Property</div>
                        <select className="nice-select fw-normal" style={{ border: 'none' }} value={saleType} onChange={(e) => setSaleType(e.target.value)}>
                            <option value="">Type</option>
                            <option value="For Sale">For Sale</option>
                            <option value="For Rent">For Rent</option>
                        </select>
                    </div>
                </div>
                <div className="col-xl-2 col-md-6">
                    <div className="input-box-one border-left">
                        <div className="label">Property</div>
                        <select className="nice-select fw-normal" style={{ border: 'none' }} value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="">Category</option>
                            <option value="Land">Land</option>
                            <option value="Houses">Houses</option>
                            <option value="Apartments">Apartments</option>
                            <option value="Commercial Property">Commercial</option>
                        </select>
                    </div>
                </div>
                <div className="col-xl-2 col-md-6">
                    <div className="input-box-one border-left">
                        <div className="label">Min Price</div>
                        <input type="number" className="type-input" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} placeholder="Enter Price" />
                    </div>
                </div>
                <div className="col-xl-2 col-md-6">
                    <div className="input-box-one border-left">
                        <div className="label">Keyword</div>
                        <input type="text" className="type-input" value={keywords} onChange={(e) => setKeywords(e.target.value)} placeholder="Fiber,Air Con.." />
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-3">
                    <div class="input-box-one lg-mt-10">
                        <div class="d-flex align-items-center justify-content-center">
                            <Link href="#" data-bs-toggle="modal" data-bs-target="#advanceFilterModal" class="search-modal-btn tran3s text-uppercase fw-500 d-inline-flex align-items-center me-3">
                                <span class="d-xl-none d-xxl-block">Advance Search</span>
                                <i class="fa-light fa-sliders-up"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-xl-1">
                    {loading ?
                        <div className='input-box-one lg-mt-10'>
                            <Circles
                                type="Oval"
                                color="#424242"
                                height={50}
                                width={50}
                            />
                        </div> :
                        <button className="fw-500 text-uppercase tran3s search-btn-two"><i className="fa-light fa-magnifying-glass"></i></button>
                    }
                </div>
                <center> {error && <div>{error}</div>} </center>
            </div>
        </form>
    );
};

export default BasicSearch;
