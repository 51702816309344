import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ListCardPage from '../components/ListCardPage';
import ListingForm from '../components/ListingForm';
import ils_07 from '../assets/images/assets/ils_07.svg';
import lazy from '../assets/images/shape/lazy.svg';
import title_shape_02 from '../assets/images/shape/title_shape_02.svg';

const PropertyTypePage = () => {
    const location = useLocation();
    const searchResults = location.state?.searchResults || []; // Access searchResults from location state



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const display = searchResults.map((property, i) => (
        <div key={i} className='col-md-6 d-flex mb-50 wow fadeInUp'>
            <ListCardPage
                title={property.title}
                address={property.address}
                city={property.city}
                state={property.state}
                price={property.price}
                sale_type={property.sale_type}
                property_type={property.property_type}
                home_type={property.home_type}
                bedrooms={property.bedrooms}
                bathrooms={property.bathrooms}
                sqft={property.sqft}
                photo_main={property.photo_main}
                slug={property.slug}
            />
        </div>
    ));


    return (
        <div className='main-page-wrapper'>
            <div class="inner-banner-one inner-banner bg-green-one text-center z-1 pt-160 lg-pt-130 pb-160 xl-pb-120 md-pb-80 position-relative">
                <div class="container">
                    <div class="title-one text-center pt-40 mb-10 xl-mb-10 lg-mb-10 wow fadeInUp">
                        <h3 class="mb-35 xl-mb-20 pt-15">Showing Search <span>Results <img src={title_shape_02} data-src={lazy} alt="" class="lazy-img"></img></span></h3>
                        <ul class="theme-breadcrumb style-none d-inline-flex align-items-center justify-content-center position-relative z-1 bottom-line">
                            <li><Link to="/">Home</Link></li>
                            <li>/</li>
                            <li>Search results</li>
                        </ul>
                    </div>
                </div>
                <img src={ils_07} alt="" class="lazy-img shapes w-100 illustration" />
            </div>
            <div class="property-listing-six bg-pink-two md-80 pb-150 xl-pb-120">
                <div class="container container-large">
                    <div class="row pt-40">
                        <div class="col-lg-8">
                            <div class="ps-xxl-5">
                                <div class="row gx-xxl-5">
                                    {display.length === 0 ? (
                                        <div>No results found.</div>
                                    ) : (
                                        display
                                    )}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 order-lg-first">
                            <div class="advance-search-panel dot-bg md-mt-80">
                                <div class="main-bg">
                                    <ListingForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyTypePage;
