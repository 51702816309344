import React, { useState } from 'react';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ListingForm = () => {
    const [category, setCategory] = useState('');
    const [saleType, setSaleType] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [bathrooms, setBathrooms] = useState('');
    const [daysListed, setDaysListed] = useState('');
    const [minPhotos, setMinPhotos] = useState('');
    const [keywords, setKeywords] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${'https://www.pevikholdings.co.ke'}/api/listings/search`, {
                category, saleType, minPrice, bedrooms, bathrooms, daysListed, minPhotos, keywords
            });
            navigate(`/property-type`, { state: { searchResults: response.data } });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
            setError('An error occurred while fetching data, try again');
        }
    };

    return (
        <form onSubmit={handleSearch}>
            <div class="row gx-lg-5">
                <div class="col-12">
                    <div class="input-box-one mb-35">
                        <div class="label">Property</div>
                        <select className="nice-select fw-normal" style={{ border: 'none', width: '100%' }} value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="">Category</option>
                            <option value="Land">Land</option>
                            <option value="Houses">Houses</option>
                            <option value="Apartments">Apartments</option>
                            <option value="Commercial Property">Commercial</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="input-box-one mb-50">
                        <div class="label">Property</div>
                        <select className="nice-select fw-normal" style={{ border: 'none', width: '100%' }} value={saleType} onChange={(e) => setSaleType(e.target.value)}>
                            <option value="">Type</option>
                            <option value="For Sale">For Sale</option>
                            <option value="For Rent">For Rent</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="input-box-one mb-35">
                        <div class="label" htmlFor='keywords'>Keyword</div>
                        <input type="text" className="type-input" value={keywords} onChange={(e) => setKeywords(e.target.value)} placeholder="Keyword" />
                    </div>
                </div>
                <div class="col-12">
                    <div class="input-box-one mb-50">
                        <div className="label">Days Listed</div>
                        <select className="nice-select fw-normal" style={{ border: 'none', width: '100%' }} value={daysListed} onChange={(e) => setDaysListed(e.target.value)}>
                            <option value="">Any</option>
                            <option value="1">1 or Less</option>
                            <option value="2">2 or Less</option>
                            <option value="3">3 or Less</option>
                            <option value="4">4 or Less</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <div className="input-box-one mb-35">
                        <div className="label">Number Of</div>
                        <input type="number" className="type-input" value={minPhotos} onChange={(e) => setMinPhotos(e.target.value)} placeholder="Property Photos" />
                    </div>
                </div>
                <div class="col-12">
                    <div className="input-box-one mb-35">
                        <div className="label">Property</div>
                        <input type="number" className="type-input" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} placeholder="Min Price" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div className="input-box-one mb-35">
                        <div className="label">Property</div>
                        <input type="number" className="type-input" value={bedrooms} onChange={(e) => setBedrooms(e.target.value)} placeholder="Min Bedrooms" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div className="input-box-one mb-35">
                        <div className="label">Property</div>
                        <input type="number" className="type-input" value={bathrooms} onChange={(e) => setBathrooms(e.target.value)} placeholder="Min Bathrooms" />
                    </div>
                </div>
                <div class="col-12">
                    {loading ?
                        <div className='fw-500 text-uppercase tran3s apply-search w-100 mt-40 mb-25'>
                            <ThreeDots
                                type="Oval"
                                color="#ffffff"
                                height={50}
                                width={50}
                                adius="9"
                                ariaLabel="three-dots-loading"
                            />
                        </div> :
                        <button class="fw-500 text-uppercase tran3s apply-search w-100 mt-40 mb-25">
                            <i class="fa-light fa-magnifying-glass"></i>
                            <span>Search</span>
                        </button>
                    }
                </div>
                <div class="col-12">
                    <div class="d-flex justify-content-between form-widget">
                        <Link href="#" class="tran3s">
                            <i class="fa-regular fa-arrows-rotate"></i>
                            <span>Reset Filter</span>
                        </Link>
                        {loading ?
                            <div className='tran3s'>
                                <ThreeDots
                                    type="Oval"
                                    color="#ffffff"
                                    height={50}
                                    width={50}
                                    adius="9"
                                    ariaLabel="three-dots-loading"
                                />
                            </div> :
                            <Link class="tran3s">
                                <i class="fa-regular fa-star"></i>
                                <span>Save Search</span>
                            </Link>
                        }
                    </div>
                </div>
                <center>{error && <div>{error}</div>} </center>
            </div>
        </form>
    );
};

ListingForm.propTypes = {
    setListings: PropTypes.func.isRequired
};

export default ListingForm;
