import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
const pagination = (props) => {
    const getNumbers = () => {
        let numbers = [];
        let itemsPerPage = props.itemsPerPage;
        let pageNumber = 1;

        for (let i = 0; i < props.count; i += itemsPerPage) {
            const page = pageNumber;
            let content = null;

            if (props.active === page) {
                content = (
                    <li  key={i} class="active">
                        <Link to="#">{pageNumber}</Link>
                    </li>
                );
            }
            else {
                content = (
                    <li key={i} onClick={() => props.visitPage(page)}>
                        <Link to="#">{pageNumber}</Link>
                    </li>
                );
            }

            numbers.push(
                content
            );
            pageNumber++;
        }

        return numbers;
    };
    
    return (

            <ul class="pagination-two d-inline-flex align-items-center justify-content-center style-none">
                <li><Link to="#" onClick={() => props.previous()}><i  class="fa-regular fa-chevron-left"></i></Link></li>
                        {getNumbers()}
                <li><Link to="#" onClick={() => props.next()}><i  class="fa-regular fa-chevron-right"></i></Link></li>
            </ul> 
    );
};

pagination.propTypes = {
    itemsPerPage: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    active: PropTypes.number.isRequired,
    visitPage: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
};

export default pagination;
