import axios from 'axios';

const fetchData = async (sortOption) => {
    try {
        let url = `${'https://www.pevikholdings.co.ke'}/api/listings/?page=1`;

        if (sortOption === 'high_price') {
            url += '&ordering=-price';
        } else if (sortOption === 'low_price') {
            url += '&ordering=price';
        } else if (sortOption === 'recent') {
            url += '&ordering=-date_added';
        }

        const res = await axios.get(url);
        return res.data;
    }
    catch (err) {
        // Handle error
        console.error(err);
    }
}

export default fetchData;