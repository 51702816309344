import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../actions/auth';
import { ThreeDots } from 'react-loader-spinner';



const Login = ({ login, isAuthenticated }) => {

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        login(email, password)
            .finally(() => setLoading(false));
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    if (isAuthenticated)
        return <Navigate to='#' />;

    return (
        <div>
            <div class="text-center mb-20">
                <h2>Welcome Back!</h2>
            </div>
            <form onSubmit={e => onSubmit(e)}>
                <div class="row">
                    <div class="col-12">
                        <div class="input-group-meta position-relative mb-25">
                            <label>Email*</label>
                            <input type='email' placeholder='Enter Email' name='email' value={email} onChange={e => onChange(e)} required />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-group-meta position-relative mb-20">
                            <label>Password*</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Enter Password'
                                name='password'
                                value={password}
                                onChange={e => onChange(e)}
                                minLength='6'
                                className="pass_log_id"
                            />
                            <span className="placeholder_icon">
                                <span
                                    className={`passVicon ${showPassword ? 'eye-slash' : ''}`}
                                    onClick={togglePasswordVisibility}
                                >
                                    <img src="assets/images/icon/icon_68.svg" alt="" />
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div
                            class="agreement-checkbox d-flex justify-content-between align-items-center">
                            <div>
                                <input type="checkbox" id="remember2" checked/>
                                <label htmlFor="remember2">By hitting the "Register" button, you agree to
                                    the <Link to="/terms">Terms conditions</Link> & <Link to="/privercy">Privacy
                                        Policy</Link></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <button class="btn-two w-100 text-uppercase d-block mt-20" onClick={onSubmit}>
                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <ThreeDots
                                        type="Oval"
                                        color="#ffffff"
                                        height={50}
                                        width={50}
                                        timeout={3000}
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                    />
                                </div>
                            ) : (
                                'Log in'
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </div>


    );
};


Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);

