import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ListingForm from "../components/ListingModalForm";
import ModalAuth from "../components/ModalAuth";

// eslint-disable-next-line

const layout = (props) => (
  <div>
    <Navbar />
    {props.children}
    <ListingForm />
    <ModalAuth />
    <Footer />
  </div>
);

export default layout;
