import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '../components/Card';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import "../Utilities/css/styles.css";
const Items = () => { 
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(`${'https://www.pevikholdings.co.ke'}/api/listings/`)
      .then(response => {
        setListings(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="row gx-xxl-5">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="col-lg-3 d-flex">
            <Stack variant="rounded" spacing={0.5}>
              <Skeleton sx={{ bgcolor: 'grey.800' }} variant="rounded" width={395} height={318} margin={20} />
              <Skeleton variant="rounded" width={370} height={70} />
              <Skeleton variant="rounded" width={350} height={50} />
            </Stack>
          </div>
        ))}
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    centerPadding: '0px',
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", fontSize: "30px", background: "black", borderRadius: "50px", padding: "1px" }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", fontSize: "30px", background: "black", borderRadius: "50px", padding: "1px" }}
        onClick={onClick}
      />
    );
  }

  return (
    <Slider {...settings}>
      {listings.map((listing, index) => (
        <Card
          key={index}
          title={listing.title}
          address={listing.address}
          city={listing.city}
          state={listing.state}
          price={listing.price}
          sale_type={listing.sale_type}
          property_type={listing.property_type}
          bedrooms={listing.bedrooms}
          bathrooms={listing.bathrooms}
          sqft={listing.sqft}
          photo_main={listing.photo_main}
          photo_1={listing.photo_1}
          photo_2={listing.photo_2}
          photo_3={listing.photo_3}
          photo_4={listing.photo_4}
          slug={listing.slug}
        />
      ))}
    </Slider>
  );
};

export default Items;
