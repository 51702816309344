import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { connect } from 'react-redux';
import { setAlert } from '../actions/alert';
import { ThreeDots } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import icon_39 from "../assets/images/icon/icon_39.svg"

const Contact = ({ setAlert }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const { name, email, subject, message } = formData;

    const [loading, setLoading] = useState(false);

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        setLoading(true);
        axios.post(`${'https://www.pevikholdings.co.ke'}/api/contacts/`, { name, email, subject, message }, config)
            .then(res => {
                setAlert('Message Sent', 'success');
                setLoading(false);
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
                window.scrollTo(0, 0);
            })
            .catch(err => {
                setAlert('Error with Sending Message', 'error');
                setLoading(false);
                window.scrollTo(0, 0);
            })
    };

    return (
        <div class="main-page-wrapper">
            <Helmet>
                <title>Realest Estate - Contact</title>
                <meta
                    name='description'
                    content='Contact us'
                />
            </Helmet>
            <div class="contact-us mt-130 xl-mt-100 pt-80 lg-pt-60">
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-9 col-xl-8 col-lg-10 m-auto">
                            <div class="title-one text-center wow fadeInUp">
                                <h3>Questions? Feel Free to Reach Out Via Message.</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="address-banner wow fadeInUp mt-60 lg-mt-40">
                    <div class="container">
                        <div class="d-flex flex-wrap justify-content-center justify-content-lg-between">
                            <div class="block position-relative z-1 mt-25">
                                <div class="d-xl-flex align-items-center">
                                    <div class="icon rounded-circle d-flex align-items-center justify-content-center"><img src={icon_39} alt="" class="lazy-img" /></div>
                                    <div class="text">
                                        <p class="fs-22">We’r always happy to help.</p>
                                        <Link to="email:info@pevik.co.ke" class="tran3s">info@pevik.co.ke</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="block position-relative skew-line z-1 mt-25">
                                <div class="d-xl-flex align-items-center">
                                    <div class="icon rounded-circle d-flex align-items-center justify-content-center"><img src={icon_39} alt="" class="lazy-img" /></div>
                                    <div class="text">
                                        <p class="fs-22">Our hotline number</p>
                                        <Link to="tel:0111 478 361" class="tran3s">0111 478 361,</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="block position-relative z-1 mt-25">
                                <div class="d-xl-flex align-items-center">
                                    <div class="icon rounded-circle d-flex align-items-center justify-content-center"><img src={icon_39} alt="" class="lazy-img" /></div>
                                    <div class="text">
                                        <p class="fs-22">Live chat</p>
                                        <Link to="/contact" class="tran3s">www.pevikholdings.com/contact</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bg-pink mt-150 xl-mt-120 md-mt-80">
                    <div class="row">
                        <div class="col-xl-7 col-lg-6">
                            <div class="form-style-one wow fadeInUp">
                                <form onSubmit={e => onSubmit(e)} id="contact-form" data-toggle="validator">
                                    <h3>Send Message</h3>
                                    <div class="messages"></div>
                                    <div class="row controls">
                                        <div class="col-12">
                                            <div class="input-group-meta form-group mb-30">
                                                <label for="">Name*</label>
                                                <input name='name' type='text' placeholder='Full Name' data-error="Your full name is required." onChange={e => onChange(e)} value={name} required />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="input-group-meta form-group mb-40">
                                                <label for="">Email*</label>
                                                <input type="email" placeholder="Email Address*" name="email" required="required" data-error="Valid email is required." onChange={e => onChange(e)} value={email} />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="input-group-meta form-group mb-40">
                                                <label for="">Subject*</label>
                                                <input type="text" placeholder="Your Message Subject*" required="required" name="subject" onChange={e => onChange(e)} value={subject} />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="input-group-meta form-group mb-35">
                                                <textarea placeholder="Your message*" name="message" required="required" data-error="Please,leave us a message." onChange={e => onChange(e)} value={message}></textarea>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-12">

                                            {loading ?
                                                <div class="btn-nine text-uppercase rounded-3 fw-normal w-100">
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                        <ThreeDots type="Oval" color="#ffffff" height={50} width={50} timeout={3000} radius="9" ariaLabel="three-dots-loading" />
                                                    </div>
                                                </div> :
                                                <button class="btn-nine text-uppercase rounded-3 fw-normal w-100" htmltype='submit'>Send Message</button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6 d-flex order-lg-first">
                            <div class="contact-map-banner w-100">
                                <div class="gmap_canvas h-100 w-100">
                                    <iframe className="gmap_iframe h-100 w-100" src="https://maps.google.com/maps?width=600&height=400&hl=en&q=JEWEL%20COMPLEX&z=12&ie=UTF8&iwloc=B&output=embed" title="Google Map"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>








        </div>
    );



};


Contact.propTypes = {
    setAlert: PropTypes.func.isRequired
};

export default connect(null, { setAlert })(Contact);
