import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from '../actions/auth'; // Update the path to your openModal action

function AuthenticatedLink(props) {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    if (!isAuthenticated) {
      event.preventDefault();
      dispatch(openModal()); // Dispatch action to open the modal
    }
  };

  return (
    <Link to={`/listings/${props.slug}`} className="title tran3s" onClick={handleClick}>{props.title}</Link>
  );
}

export default AuthenticatedLink;
