import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from 'react-bootstrap/Carousel';
import AuthenticatedLink from '../hocs/AuthenticatedLink.js';
import AuthListLinkIcon from '../hocs/AuthListLinkIcon.js';

const ListCardPage = (props) => {
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    return (
        <div class="listing-card-one border-25 h-100 w-100">
            <div class="img-gallery p-15">
                <div class="position-relative border-25 overflow-hidden">
                    {props.sale_type === 'For Sale' ? (
                        <div class="tag sale border-25">{props.sale_type}</div>
                    ) : (
                        <div class="tag border-25">{props.sale_type}</div>
                    )}
                    <Link to="#" class="fav-btn tran3s"><i class="fa-light fa-heart"></i></Link>
                    <Carousel id="carousel1" className="carousel slide">
                        <Carousel.Item className="active" interval={1000000}>
                            <Link to="#" className="d-block">
                                <img src={props.photo_main} className="w-100" alt="..." />
                            </Link>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            <div class="property-info p-25">
                <AuthenticatedLink slug={props.slug} title={props.title} />
                <div class="address"><i class="fa-duotone fa-location-dot"></i>{props.address}</div>
                <ul class="style-none feature d-flex flex-wrap align-items-center justify-content-between">
                    {props.bedrooms && (
                        <li class="d-flex align-items-center">
                            <img src="assets/images/icon/icon_05.svg" alt="" class="lazy-img icon me-2" />
                            <span class="fs-16">{props.bedrooms} Bed</span>
                        </li>
                    )}
                    {props.bathrooms && (
                        <li class="d-flex align-items-center">
                            <img src="assets/images/icon/icon_06.svg" alt="" class="lazy-img icon me-2" />
                            <span class="fs-16">{props.bathrooms} Bath</span>
                        </li>
                    )}
                    <li class="d-flex align-items-center">
                        <i class="fa-thin fa-ballot-check lazy-img icon me-2"></i>
                        <span class="fs-16"> {props.property_type}</span>
                    </li>
                    <li class="d-flex align-items-center">
                        <img src="assets/images/icon/icon_04.svg" alt="" class="lazy-img icon me-2" />
                        <span class="fs-16">{props.sqft} Sqft</span>
                    </li>
                </ul>
                <div class="pl-footer top-border d-flex align-items-center justify-content-between">
                    <strong class="price fw-500 color-dark">Ksh{numberWithCommas(props.price)}</strong>
                    <AuthListLinkIcon
                        to={`/listings/${props.slug}`}
                        className="btn-four rounded-circle"
                        isAuthenticated={props.isAuthenticated}
                    >
                        <i class="bi bi-arrow-up-right"></i>
                    </AuthListLinkIcon>
                </div>
            </div>
        </div>
    )
}
ListCardPage.propTypes = {
    title: PropTypes.string.isRequired,
    photo_main: PropTypes.string.isRequired,
    photo_1: PropTypes.string,
    photo_2: PropTypes.string,
    photo_3: PropTypes.string,
    photo_4: PropTypes.string,
    photo_5: PropTypes.string,
    photo_6: PropTypes.string,
    photo_7: PropTypes.string,
    photo_8: PropTypes.string,
    photo_9: PropTypes.string,
    photo_10: PropTypes.string,
    photo_11: PropTypes.string,
    photo_12: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.string,
    sale_type: PropTypes.string.isRequired,
    property_type: PropTypes.string.isRequired,
    sqft: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
};
export default ListCardPage