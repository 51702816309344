import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from '../actions/auth'; // Update the path to your openModal action

function ProjectAuthLink(props) {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    if (!isAuthenticated) {
      event.preventDefault();
      dispatch(openModal()); // Dispatch action to open the modal
    }
  };

  return (
    <h3><Link to={`/projects/${props.slug}`} className="tran3s" onClick={handleClick}>{props.title}</Link></h3>
  );
}

export default ProjectAuthLink;
