import React from 'react';
import { useLocation } from 'react-router-dom';

const SearchResults = () => {
    const location = useLocation();
    const listings = location.state.listings;

    // Render the search results
    return (
        <div>
            <h2>Search Results</h2>
            {/* Render search results here */}
            {listings.map(listing => (
                <div key={listing.id}>
                    {/* Render individual listing details */}
                </div>
            ))}
        </div>
    );
}

export default SearchResults;