import React, { useEffect } from 'react';
import logo from '../assets/images/logo/logo.png';
import lazy from '../assets/images/shape/lazy.svg';
import shape_47 from '../assets/images/shape/shape_47.svg';
import shape_46 from '../assets/images/shape/shape_46.svg';

const Footer = () => {

  useEffect(() => {
    const checkScrollTop = () => {
      if (!window.scrollY) {
        document.querySelector('.scroll-top')?.classList.remove('show');
      } else if (window.scrollY > 200) {
        document.querySelector('.scroll-top')?.classList.add('show');
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  return (
    <div>
      <div className="footer-two">
        <div className="container container-large">
          <div className="bg-wrapper position-relative z-1">
            <div className="news-letter-area">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="text-center text-lg-start md-mb-20">
                    <h2>Our Newsletter</h2>
                    <p className="fs-20 m0">
                      Get instant news by subscribe to our newsletter
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-wrapper me-auto ms-auto me-lg-0">
                    <form action="#">
                      <input type="email" placeholder="Your email address" />
                      <button>
                        <i className="fa-light fa-arrow-right-long"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-xl-3">
                <div className="footer-intro position-relative z-1 pt-70 pb-150 lg-pb-20">
                  <div className="logo mb-15">
                    <a href="index.html">
                      <img src={logo} alt="" width="200" />
                    </a>
                  </div>

                  <p className="mb-45 lg-mb-30 pe-2 pe-lg-5">
                    Roysambu, Jewel Complex 2nd Floor Room 212
                  </p>
                  <ul className="style-none contact-info">
                    <li className="d-flex align-items-center">
                      <img src="images/icon/icon_30.svg" alt="" width="20" />
                      <a href="/">
                        <i className="fa-solid fa-envelope"></i>
                        &nbsp;&nbsp;info@pevik.co.ke
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <img src="images/icon/icon_31.svg" alt="" width="20" />
                      <a href="tel:0111478361">
                        <i className="fa-solid fa-phone-volume"></i>
                        &nbsp;&nbsp;0111 478 361
                      </a>
                    </li>
                  </ul>
                  <ul className="style-none d-flex align-items-center social-icon">
                    <li>
                      <a href="/">
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                  <img
                    src={shape_46}
                    data-src={lazy}
                    alt=""
                    className="lazy-img shapes shape_01 d-none d-xl-block"
                  />
                </div>
              </div>
              <div className="col-xl-9">
                <div className="ms-xxl-5 ps-xl-5 mt-200 lg-mt-20">
                  <div className="row justify-content-between">
                    <div className="col-lg-3 col-md-4">
                      <div className="footer-nav pt-30">
                        <h5 className="footer-title">Links</h5>
                        <ul className="footer-nav-link style-none">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/about">About</a>
                          </li>
                          <li>
                            <a href="/listings">Listings</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="footer-nav pt-30">
                        <ul className="footer-nav-link style-none">
                          <li>
                            <a href="/projects">Projects</a>
                          </li>
                          <li>
                            <a href="/contact">Contact Us</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-lg-3 col-md-4">
                      <div className="footer-nav pt-30">
                        <h5 className="footer-title">Legal</h5>
                        <ul className="footer-nav-link style-none">
                          <li>
                            <a href="/faq">Faq’s</a>
                          </li>
                          <li>
                            <a href="/privacypolicy">Privacy policy</a>
                          </li>
                          <li>
                            <a href="/termscondition">Terms & conditions</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-lg-2 d-none d-lg-block">
                      <img
                        src={shape_47}
                        data-src={lazy}
                        alt=""
                        className="lazy-img mt-50"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-footer">
            <div className="d-md-flex justify-content-center justify-content-md-between align-items-center">
              <ul className="style-none bottom-nav d-flex flex-wrap justify-content-center">
                <li>
                  <a href="/privacypolicy">Privacy &amp; Terms</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
              <p className="mb-15 text-center text-lg-start order-md-first">
                Copyright @2024 Pevik Holdings Ltd.
              </p>
            </div>
          </div>
        </div>
      </div>
      <button className="scroll-top" onClick={scrollTop} style={{ display: 'none' }}>
        <i className="bi bi-arrow-up-short"></i>
      </button>
    </div>
  )
}

export default Footer