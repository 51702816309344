import React, { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from 'react-bootstrap';

const ProjectsComponent = ({ projects }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (projects.length > 0) {
      setLoading(false); // Set loading to false when projects are loaded
    }
  }, [projects]);

  const getProjects = () => {
    const result = [];
    const projectsOnPage = projects.map((project, index) => {
      const projectClass = project.project_type.toLowerCase().replace(' ', '');

      return (
        <div key={project.id} className={`isotop-item ${projectClass}`}>
          <ProjectCard
            title={project.title}
            photo_main={project.photo_main}
            photo_1={project.photo_1}
            photo_2={project.photo_2}
            photo_3={project.photo_3}
            description={project.description}
            project_type={project.project_type}
            list_date={project.list_date}
            slug={project.slug}
          />
        </div>
      );
    });

    for (let i = 0; i < projects.length; i += 3) {
      result.push(
        <div key={`row-${i}`} className='row'>
          {projectsOnPage[i]}
          {projectsOnPage[i + 1] ? projectsOnPage[i + 1] : null}
          {projectsOnPage[i + 2] ? projectsOnPage[i + 2] : null}
        </div>
      );
    }

    return result;
  };


  return (
    <div>
      {loading ? (
        <div>
          <div class="isotop-item house flat">
            <div class="project-block-three mt-80 lg-mt-50">
              <div class="row gx-xxl-5 align-items-center">
                <div class="col-lg-6">
                  <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                    <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={695} height={458} margin={20} />
                  </Stack>
                </div>
                <div class="col-lg-6 ms-auto">
                  <div class="caption ps-xxl-5">
                    <div class="tag fw-500 text-uppercase">
                      <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                        <Skeleton varient="rounded" width={370} height={70} />
                      </Stack>
                    </div>
                    <div>
                      <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                        <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={495} height={258} margin={20} />
                      </Stack>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="isotop-item house flat">
            <div class="project-block-three mt-80 lg-mt-50">
              <div class="row gx-xxl-5 align-items-center">
                <div class="col-lg-6">
                  <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                    <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={695} height={458} margin={20} />
                  </Stack>
                </div>
                <div class="col-lg-6 ms-auto">
                  <div class="caption ps-xxl-5">
                    <div class="tag fw-500 text-uppercase">
                      <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                        <Skeleton varient="rounded" width={370} height={70} />
                      </Stack>
                    </div>
                    <div>
                      <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                        <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={495} height={258} margin={20} />
                      </Stack>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="isotop-item house flat">
            <div class="project-block-three mt-80 lg-mt-50">
              <div class="row gx-xxl-5 align-items-center">
                <div class="col-lg-6">
                  <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                    <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={695} height={458} margin={20} />
                  </Stack>
                </div>
                <div class="col-lg-6 ms-auto">
                  <div class="caption ps-xxl-5">
                    <div class="tag fw-500 text-uppercase">
                      <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                        <Skeleton varient="rounded" width={370} height={70} />
                      </Stack>
                    </div>
                    <div>
                      <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                        <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={495} height={258} margin={20} />
                      </Stack>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="isotop-item house flat">
            <div class="project-block-three mt-80 lg-mt-50">
              <div class="row gx-xxl-5 align-items-center">
                <div class="col-lg-6">
                  <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                    <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={695} height={458} margin={20} />
                  </Stack>
                </div>
                <div class="col-lg-6 ms-auto">
                  <div class="caption ps-xxl-5">
                    <div class="tag fw-500 text-uppercase">
                      <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                        <Skeleton varient="rounded" width={370} height={70} />
                      </Stack>
                    </div>
                    <div>
                      <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                        <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={495} height={258} margin={20} />
                      </Stack>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        getProjects()
      )}
    </div>
  );
};

export default ProjectsComponent;