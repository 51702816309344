import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const FeaturedProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await axios.get(`${'https://www.pevikholdings.co.ke'}/api/projects/?ordering=-date&limit=2`);

        setProjects(res.data.results);
        setLoading(false);
      }
      catch (err) {
        console.log('API request failed:', err);
      }
    };

    fetchProjects();
  }, []);

  const display = projects.map((project, index) => {
    switch (index) {
      case 0:
        return (
          <div key={project.id} class="col-lg-8 d-flex">
            <div class="listing-card-three w-100 h-100 position-relative z-1 wow fadeInUp" style={{ backgroundImage: `url(${project.photo_1})` }}>
              <div class="w-100 h-100 d-flex flex-column">
                <div class="tag fw-500 text-uppercase">Featured</div>
                <div class="mt-100 mt-sm-auto wrapper d-flex flex-wrap justify-content-between align-items-center">
                  <div class="property-name h-100">
                    <h5 class="text-white mb-15">{project.title}</h5>
                    <p class="m0 text-white"><i class="fa-duotone fa-location-dot"></i> {project.address}</p>
                  </div>
                  <div class="property-info h-100">
                    <ul class="style-none feature d-flex flex-wrap align-items-center justify-content-between pb-5">
                      <li class="d-flex align-items-center">
                        <div class="fs-16"><span>{project.units} </span> Units</div>
                      </li>
                      <li class="d-flex align-items-center">
                        <div class="fs-16"><span><i class="fa-solid fa-file-certificate fa-fade"></i></span>Quality<br />Assuared</div>
                      </li>
                      <li class="d-flex align-items-center">
                        <div class="fs-16"><span><i class="fa-solid fa-school fa-fade"></i></span> CCTV<br />Servailance</div>
                      </li>
                      <li class="d-flex align-items-center">
                        <div class="fs-16"><span><i class="fa-solid fa-hospital fa-fade"></i></span> Finger<br />print </div>
                      </li>
                    </ul>
                    <div class="d-sm-flex justify-content-between align-items-center mt-10">
                      <Link to={`/projects/${project.slug}`} class="btn-ten xs-mt-20"><span>Full
                        Details</span> <i class="bi bi-arrow-up-right"></i></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div class="col-lg-4 d-flex">
            <div class="w-100 h-100">
              <div class="row h-100">
                <div key={project.id} class="col-lg-12 col-md-6">
                  <div class="listing-card-two w-100 position-relative z-1 md-mt-40 wow fadeInUp" style={{ backgroundImage: `url(${project.photo_1})` }}>
                    <div class="w-100 h-100 d-flex flex-column">
                      <div class="tag fw-500 text-uppercase">Featured</div>
                      <div class="mt-auto d-lg-flex justify-content-between align-items-center">
                        <div class="md-mb-20">
                          <h5 class="text-white">{project.title}</h5>
                          <p class="m0 text-white"><i class="fa-duotone fa-location-dot"></i> {project.address}</p>
                        </div>
                        <Link to={`/projects/${project.slug}`}
                          class="btn-four rounded-circle inverse stretched-link"><i
                            class="bi bi-arrow-up-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  });

  const skeleton = (
    <div className="row gx-xxl-5">
        <div className="col-lg-8 d-flex">
        <Stack spacing={1}>
            <Skeleton variant="rounded" width={910} height={418} />
            
        </Stack>

        </div>
        <div className="col-lg-4 d-flex">
            <Skeleton variant="rounded" width={510} height={418} />
        </div>
    </div>
); 

  return (
    <div class="row gx-xxl-5">
      {loading ? skeleton : display} 
    </div>
  );
};

export default FeaturedProjects;