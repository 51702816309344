import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { openModal } from '../actions/auth';
import { logout } from '../actions/auth';
import PropTypes from 'prop-types';
import logo from '../assets/images/logo/logo.png';


const Navbar = ({ auth: { isAuthenticated, loading }, logout }) => {

  const dispatch = useDispatch();

  const handleLoginClick = () => {
    dispatch(openModal());
  };

  const authLinks = (
    <a className='fw-500 tran3s' onClick={logout} href='#!'><i class="fa-regular fa-left-from-bracket"></i>&nbsp;Logout</a>
  );

  const guestLinks = (
    <Fragment>
      <Link className='fw-500 tran3s' onClick={handleLoginClick}><i class="fa-solid fa-user-unlock"></i> Login<sapn>&nbsp; <i class="fa-solid fa-slash-forward"></i></sapn> Sign Up</Link>
    </Fragment>
  );

  return (
    <Fragment>
      <header className="theme-main-menu menu-overlay menu-style-two sticky-menu">
        <div className="alert-wrapper text-center">
          <p className="fs-16 m0 text-white">
            <i className="fa-duotone fa-phone-volume"></i> &nbsp;&nbsp;call Us:{" "}
            <a href="tel:0111478361" type="tel" className="fw-500">
              0111 478 361
            </a>
            &nbsp;&nbsp;{" "}
            <i className="fa-duotone fa-building-circle-arrow-right fa-fade"></i>
            &nbsp;&nbsp; Office Location:{" "}
            <span>Roysambu, Jewel Complex 2nd Floor Room 212</span>
          </p>

        </div>

        <div className="inner-content gap-one">
          <div className="top-header position-relative">
            <div className="d-flex align-items-center">
              <div className="logo order-lg-0">
                <Link to="/" className="d-flex align-items-center">
                  <img src={logo} alt="" width="200" />
                </Link>
              </div>
              <div className="right-widget ms-auto me-3 me-lg-0 order-lg-3">
                <ul className="d-flex align-items-center style-none">
                  <li className="d-flex align-items-center login-btn-one">
                    {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}
                  </li>
                </ul>
              </div>
              <nav className="navbar navbar-expand-lg p0 ms-lg-5 order-lg-2">
                <button
                  className="navbar-toggler d-block d-lg-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav align-items-lg-center">
                    <li className="d-block d-lg-none">
                      <div className="logo">
                        <Link to="/" className="d-block">
                          <img src={logo} alt="" />
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/about">
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/projects">
                        Projects
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/listings">
                        Listings
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

    </Fragment>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,

}



const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(Navbar);





