import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from '../components/Pagination';
import ProjectsComponent from '../components/ProjectsComponent';
import ils_07 from '../assets/images/assets/ils_07.svg';
import lazy from '../assets/images/shape/lazy.svg';
import title_shape_02 from '../assets/images/shape/title_shape_02.svg';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [count, setCount] = useState(1);
    const [previous, setPrevious] = useState('');
    const [next, setNext] = useState('');
    const [active, setActive] = useState('');
    const [activeFilter, setActiveFilter] = useState('*');
    const [filteredProjects, setFilteredProjects] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axios.get(`${'https://www.pevikholdings.co.ke'}/api/projects/?page=1`);
                setProjects(res.data.results);
                setCount(res.data.count);
                setPrevious(res.data.previous);
                setNext(res.data.next);
            }
            catch (err) {
                console.log('API request failed:', err);
            }
        }

        fetchData();
    }, []);

    const visitPage = (page) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/projects/?page=${page}`)
            .then(res => {
                setProjects(res.data.results);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                setActive(page);
            })
            .catch(err => {

            });
    };

    const previous_number = () => {
        axios.get(previous)
            .then(res => {
                setProjects(res.data.results);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                if (previous)
                    setActive(active - 1);
            })
            .catch(err => {

            });
    };

    const next_number = () => {
        axios.get(next)
            .then(res => {
                setProjects(res.data.results);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                if (next)
                    setActive(active + 1);
            })
            .catch(err => {

            });
    };

    useEffect(() => {
        setFilteredProjects(projects);
    }, [projects]);

    const handleFilterChange = (filterValue) => {
        setActiveFilter(filterValue);
        if (filterValue === '*') {
            setFilteredProjects(projects);
        } else {
            const filtered = projects.filter(project => project.project_type === filterValue);
            setFilteredProjects(filtered);
        }
    };

    return (
        <div class="main-page-wrapper">
            <div class="inner-banner-one inner-banner bg-green-one text-center z-1 pt-160 lg-pt-130 pb-160 xl-pb-120 md-pb-80 position-relative">
                <div class="container">
                <div class="title-one text-center pt-40 mb-10 xl-mb-10 lg-mb-10 wow fadeInUp">
					<h3 class="mb-35 xl-mb-20 pt-15">Project <span>Listings <img src={title_shape_02} data-src={lazy} alt="" class="lazy-img"></img></span></h3>
					<ul class="theme-breadcrumb style-none d-inline-flex align-items-center justify-content-center position-relative z-1 bottom-line">
						<li><Link to="/">Home</Link></li>
						<li>/</li>
						<li>Project Listings</li>
					</ul>
				</div>
                </div>
                <img src={ils_07} alt="" class="lazy-img shapes w-100 illustration" />
            </div>
            <div class="project-section-one mb-170 xl-mb-100">
                <div class="container">
                    <div class="filter-nav-one style-two border-line d-lg-flex align-items-center justify-content-between">
                        <ul className="style-none d-flex justify-content-center flex-wrap isotop-menu-wrapper">
                            <li onClick={() => handleFilterChange('*')} className={activeFilter === '*' ? 'is-checked' : ''}>All</li>
                            <li onClick={() => handleFilterChange('Development Project')} className={activeFilter === 'Development Project' ? 'is-checked' : ''}>Development Plan</li>
                            <li onClick={() => handleFilterChange('Off Plan Project')} className={activeFilter === 'Off Plan Project' ? 'is-checked' : ''}>Off Plan</li>
                            <li onClick={() => handleFilterChange('Ready Project')} className={activeFilter === 'Ready Project' ? 'is-checked' : ''}>Ready Plan</li>
                        </ul>
                        <ul class="style-none d-flex align-items-center justify-content-center social-icon md-mt-20">
                            <li>Filter Project by Type</li>
                        </ul>
                    </div>

                    <div id="isotop-gallery-wrapper" class="grid-1column pt-40 lg-pt-30">
                        <div class="grid-sizer"></div>
                        <ProjectsComponent projects={filteredProjects} />
                    </div>
                    <center>
                    <div class="pt-20">
                    <Pagination
                        itemsPerPage={3}
                        count={count}
                        visitPage={visitPage}
                        previous={previous_number}
                        next={next_number}
                        active={active}
                        setActive={setActive}
                    />
                    </div>
                    </center>
                </div>
            </div>
        </div>
    );
};

export default Projects;