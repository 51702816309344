import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./containers/Home";
import About from "./containers/About";
import Contact from "./containers/Contact";
import Listings from "./containers/Listings";
import ListingDetail from "./containers/ListingDetail";
import ProjectDetails from "./containers/ProjectDetails";
import Projects from "./containers/Projects";
import Layout from "./hocs/Layout";
import NotFound from "./components/NotFound";
import SearchResults from "./containers/SearchResults";
import { Provider } from "react-redux";
import store from "./store";
import initializeFeatures from "./Util/Utils";
import FeaturedProjects from "./components/FeturedProjects";
import SignUp from "./components/SignUp";
import PropertyTypePage from "./components/PropertyTypePage";
import TermsCondition from "./containers/TermsCondition";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Faq from "./containers/Faq";
initializeFeatures();




const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/property-type" element={<PropertyTypePage />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/termscondition" element={<TermsCondition />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/listings" element={<Listings />} />
            <Route path='/listings/:id' element={<ListingDetail />} />
            <Route path='/projects/:id' element={<ProjectDetails />} />
            <Route path="/search-results" element={<SearchResults />} />
            <Route path="/featured-projects" element={<FeaturedProjects />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
};

export default App;
