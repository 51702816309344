import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { setAlert } from '../actions/alert';
import { signup } from '../actions/auth';
import PropTypes from 'prop-types';
import { ThreeDots } from 'react-loader-spinner';

const SignUp = ({ setAlert, signup, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password2: '' // Added password2 field in the state
    });

    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const { name, email, password, password2 } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        if (password !== password2) {
            setAlert('Passwords do not match', 'error');
            setLoading(false); // Set loading to false if passwords do not match
        } else {
            signup({ name, email, password, password2 })
                .finally(() => setLoading(false)); // Call .finally() on the Promise returned by signup()
        }
    };



    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    if (isAuthenticated)
        return <Navigate to='/' />;

    return (
        <div>
            <div className="mt-30">
                <div class="text-center mb-20">
                    <h2>Register</h2>
                </div>
            </div>
            <form onSubmit={e => onSubmit(e)}>
                <div class="row">
                    <div class="col-12">
                        <div class="input-group-meta position-relative mb-25">
                            <label>Name*</label>
                            <input type='text' placeholder='Name' name='name' value={name} onChange={e => onChange(e)} required />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-group-meta position-relative mb-25">
                            <label>Email*</label>
                            <input type='email' placeholder='Enter Email' name='email' value={email} onChange={e => onChange(e)} required />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-group-meta position-relative mb-20">
                            <label>Password*</label>
                            <input type={showPassword ? 'text' : 'password'} placeholder='Password' name='password' value={password} onChange={e => onChange(e)} minLength='6' class="pass_log_id" />
                            <span className="placeholder_icon">
                                <span
                                    className={`passVicon ${showPassword ? 'eye-slash' : ''}`}
                                    onClick={togglePasswordVisibility}
                                >
                                    <img src="assets/images/icon/icon_68.svg" alt="" />
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-group-meta position-relative mb-20">
                            <label>Confirm Password*</label>
                            <input type={showPassword ? "text" : "password"} name="password2" value={password2} onChange={e => onChange(e)} placeholder="Confirm Password" required />
                        </div>
                    </div>

                    <div class="col-12">
                    <button class="btn-two w-100 text-uppercase d-block mt-20" onClick={onSubmit}>
                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <ThreeDots
                                        type="Oval"
                                        color="#ffffff"
                                        height={50}
                                        width={50}
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                    />
                                </div>
                            ) : (
                                'Sign Up'
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

SignUp.propTypes = {
    setAlert: PropTypes.func.isRequired,
    signup: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, signup })(SignUp);
