import $ from "jquery";
import "bootstrap";
import "slick-carousel/slick/slick"; // Import slick-carousel theme styles
import "isotope-layout/dist/isotope.pkgd";

// Utils.js

const initializeFeatures = () => {
  // Check if the slider element exists before initializing Slick
  (function ($) {
    //-------------- Click event to scroll to top
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 200) {
        $(".scroll-top").fadeIn();
      } else {
        $(".scroll-top").fadeOut();
      }
    });
    $(".scroll-top").on("click", function () {
      $("html, body").animate({ scrollTop: 0 });
      return false;
    });

    // --------------------- Add .active class to current navigation based on URL
    var pgurl = window.location.href.substr(
      window.location.href.lastIndexOf("/") + 1
    );
    $(".navbar-nav > li  a").each(function () {
      if ($(this).attr("href") === pgurl || $(this).attr("href") === "")
        $(this).addClass("active");
      // $(this).parent("li").addClass("active");
    });

    // ----------------------------- Counter Function
    var timer = $(".counter");
    if (timer.length) {
      $(".counter").counterUp({
        delay: 10,
        time: 1200,
      });
    }

    // ------------------------ Navigation Scroll
    $(window).on("scroll", function () {
      var sticky = $(".sticky-menu"),
        scroll = $(window).scrollTop();
      if (scroll >= 180) sticky.addClass("fixed");
      else sticky.removeClass("fixed");
    });

    // -------------------- Remove Placeholder When Focus Or Click
    $("input,textarea").each(function () {
      $(this).data("holder", $(this).attr("placeholder"));
      $(this).on("focusin", function () {
        $(this).attr("placeholder", "");
      });
      $(this).on("focusout", function () {
        $(this).attr("placeholder", $(this).data("holder"));
      });
    });

    // ---------------------- Filter More Review Button
    if ($(".load-more-review").length) {
      $(".load-more-review").on("click", function () {
        let $more = $(this).siblings(".review-wrapper").toggleClass("show");

        if ($more.hasClass("show")) {
          $(this).html('SHow less <i class="bi bi-arrow-up-right"></i>');
        } else {
          $(this).html(
            'VIEW ALL 120 REVIEWS <i class="bi bi-arrow-up-right"></i>'
          );
        }
      });
    }

    // ------------------------ Password Toggler
    if($(".user-data-form").length) {
      $(".passVicon").on('click', function() {
        $(".passVicon").toggleClass("eye-slash");
        var input = $(".pass_log_id");
        if (input.attr("type") === "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }

      });
    }

    // ------------------------ Hero Slider One
    if ($(".hero-slider-one").length) {
      $(".hero-slider-one").slick({
        dots: false,
        arrows: false,
        lazyLoad: "ondemand",
        centerPadding: "0px",
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        autoplaySpeed: 7000,
      });
    }

    // ------------------------ Feedback Slider One
    if ($(".feedback-slider-one").length) {
      $(".feedback-slider-one").slick({
        dots: true,
        arrows: false,
        lazyLoad: "ondemand",
        centerPadding: "0px",
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 300000,
      });
    }
    // ------------------------ Feedback Slider Two
    if ($(".feedback-slider-two").length) {
      $(".feedback-slider-two").slick({
        dots: false,
        arrows: true,
        prevArrow: $(".prev_c"),
        nextArrow: $(".next_c"),
        lazyLoad: "ondemand",
        centerPadding: "0px",
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    }
    // ------------------------ Feedback Slider Three
    if ($(".feedback-slider-three").length) {
      $(".feedback-slider-three").slick({
        dots: true,
        arrows: false,
        lazyLoad: "ondemand",
        centerPadding: "0px",
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    }

    // -------------------- Site Preloader
    $("#ctn-preloader").fadeOut(); // will first fade out the loading animation
    $("#preloader").delay(350).fadeOut("slow"); // will fade out the white DIV that covers the website.
    $("body").delay(350).css({ overflow: "visible" });

    // ----------------------------- isotop gallery
    if ($("#isotop-gallery-wrapper").length) {
      var $grid = $("#isotop-gallery-wrapper").isotope({
        // options
        itemSelector: ".isotop-item",
        percentPosition: true,
        masonry: {
          // use element for option
          columnWidth: ".grid-sizer",
        },
      });

      // filter items on button click
      $(".isotop-menu-wrapper").on("click", "li", function () {
        var filterValue = $(this).attr("data-filter");
        $grid.isotope({ filter: filterValue });
      });

      // change is-checked class on buttons
      $(".isotop-menu-wrapper").each(function (i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on("click", "li", function () {
          $buttonGroup.find(".is-checked").removeClass("is-checked");
          $(this).addClass("is-checked");
        });
      });
    }
  })($);
};



export default initializeFeatures;
