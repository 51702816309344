import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ListCardPage from '../components/ListCardPage';
import fetchData from '../components/FetchData';
import Pagination from '../components/Pagination';
import ListingForm from '../components/ListingForm';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import ils_07 from '../assets/images/assets/ils_07.svg';
import lazy from '../assets/images/shape/lazy.svg';
import title_shape_02 from '../assets/images/shape/title_shape_02.svg';

const Listings = () => {
    const [listings, setListings] = useState([]);
    const [count, setCount] = useState(0);
    const [previous, setPrevious] = useState('');
    const [next, setNext] = useState('');
    const [active, setActive] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axios.get(`${'https://www.pevikholdings.co.ke'}/api/listings/?page=1`);
                setListings(res.data.results);
                console.log(res.data.results);
                setCount(res.data.count);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                setIsLoading(false);
            }
            catch (error) {
                console.error('Error fetching data: ', error);
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    if (isLoading) {
        return <div className='main-page-wrapper'>
            <div class="inner-banner-one inner-banner bg-pink text-center z-1 pt-160 lg-pt-130 pb-160 xl-pb-120 md-pb-80 position-relative">
                <div class="container">
                    <div class="title-one text-center pt-40 mb-10 xl-mb-10 lg-mb-10 wow fadeInUp">
                        <h3 class="mb-35 xl-mb-20 pt-15">Find Your <span>Home <img src={title_shape_02} data-src={lazy} alt="" class="lazy-img"></img></span></h3>
                        <ul class="theme-breadcrumb style-none d-inline-flex align-items-center justify-content-center position-relative z-1 bottom-line">
                            <li><Link to="/">Home</Link></li>
                            <li>/</li>
                            <li>Property Listing</li>
                        </ul>
                    </div>
                </div>
                <img src={ils_07} alt="" class="lazy-img shapes w-100 illustration" />
            </div>
            <div class="property-listing-six bg-pink md-pt-80 pb-150 xl-pb-120">
                <div class="container container-large">
                    <div class="row pt-40">
                        <div class="col-lg-8">
                            <div class="ps-xxl-5">
                                <div class="listing-header-filter d-sm-flex justify-content-between align-items-center mb-40 lg-mb-30">
                                    <Skeleton varient="rounded" width={300} height={50} />
                                    <div class="d-flex align-items-center xs-mt-20">
                                        <div class="short-filter d-flex align-items-center">
                                            <Skeleton varient="rounded" width={300} height={50} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-xxl-5">
                                    <div class="col-md-6 d-flex mb-50 wow fadeInUp">
                                        <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                            <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={395} height={318} margin={20} />
                                            <Skeleton varient="rounded" width={370} height={70} />
                                            <Skeleton varient="rounded" width={350} height={50} />
                                        </Stack>
                                    </div>
                                    <div class="col-md-6 d-flex mb-50 wow fadeInUp">
                                        <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                            <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={395} height={318} margin={20} />
                                            <Skeleton varient="rounded" width={370} height={70} />
                                            <Skeleton varient="rounded" width={350} height={50} />
                                        </Stack>
                                    </div>
                                    <div class="col-md-6 d-flex mb-50 wow fadeInUp">
                                        <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                            <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={395} height={318} margin={20} />
                                            <Skeleton varient="rounded" width={370} height={70} />
                                            <Skeleton varient="rounded" width={350} height={50} />
                                        </Stack>
                                    </div>
                                    <div class="col-md-6 d-flex mb-50 wow fadeInUp">
                                        <Stack sx={{ bgcolor: 'grey.800' }} variant="rounded" spacing={0.5}>
                                            <Skeleton sx={{ bgcolor: 'grey.600' }} variant="rounded" width={395} height={318} margin={20} />
                                            <Skeleton varient="rounded" width={370} height={70} />
                                            <Skeleton varient="rounded" width={350} height={50} />
                                        </Stack>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 order-lg-first">
                            <div class="advance-search-panel dot-bg md-mt-80">
                                <div class="main-bg">
                                    <Skeleton varient="rounded" width={370} height={90} />
                                    <Skeleton varient="rounded" width={370} height={90} />
                                    <Skeleton varient="rounded" width={370} height={90} />
                                    <Skeleton varient="rounded" width={370} height={90} />
                                    <Skeleton varient="rounded" width={370} height={90} />
                                    <Skeleton varient="rounded" width={370} height={90} />
                                    <Skeleton varient="rounded" width={370} height={90} />
                                    <Skeleton varient="rounded" width={370} height={90} />
                                    <Skeleton varient="rounded" width={370} height={90} />
                                    <Skeleton varient="rounded" width={370} height={90} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    const display = listings.map((listing, i) => (
        <div key={i} className='col-md-6 d-flex mb-50 wow fadeInUp'>
            <ListCardPage
                title={listing.title}
                address={listing.address}
                city={listing.city}
                state={listing.state}
                price={listing.price}
                sale_type={listing.sale_type}
                property_type={listing.property_type}
                home_type={listing.home_type}
                bedrooms={listing.bedrooms}
                bathrooms={listing.bathrooms}
                sqft={listing.sqft}
                photo_main={listing.photo_main}
                slug={listing.slug}
            />
        </div>
    ));

    const visitPage = (page) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/listings/?page=${page}`)
            .then(res => {
                setListings(res.data.results);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                setActive(page);

            })
            .catch(error => {

            });
    };
    
    const previous_number = () => {
        axios.get(previous)
            .then(res => {
                setListings(res.data.results);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                if (previous)
                    setActive(active - 1);
            })
            .catch(err => {

            });
    };

    const next_number = () => {
        axios.get(next)
            .then(res => {
                setListings(res.data.results);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                if (next)
                    setActive(active + 1);
            })
            .catch(err => {

            });
    };

    return (
        <div className='main-page-wrapper'>
            <Helmet>
                <title>Realest Estate - Listings</title>
                <meta
                    name='description'
                    content='Listings page'
                />
            </Helmet>
            <div class="inner-banner-one inner-banner bg-green-one text-center z-1 pt-160 lg-pt-130 pb-160 xl-pb-120 md-pb-80 position-relative">
                <div class="container">
                    <div class="title-one text-center pt-40 mb-10 xl-mb-10 lg-mb-10 wow fadeInUp">
                        <h3 class="mb-35 xl-mb-20 pt-15">Find Your <span>Home <img src={title_shape_02} data-src={lazy} alt="" class="lazy-img"></img></span></h3>
                        <ul class="theme-breadcrumb style-none d-inline-flex align-items-center justify-content-center position-relative z-1 bottom-line">
                            <li><Link to="/">Home</Link></li>
                            <li>/</li>
                            <li>Property Listing</li>
                        </ul>
                    </div>
                </div>
                <img src={ils_07} alt="" class="lazy-img shapes w-100 illustration" />
            </div>
            <div class="property-listing-six bg-pink-two md-pt-80 pb-150 xl-pb-120">
                <div class="container container-large">
                    <div class="row pt-40">
                        <div class="col-lg-8">
                            <div class="ps-xxl-5">
                                <div class="listing-header-filter d-sm-flex justify-content-between align-items-center mb-40 lg-mb-30">
                                    <div>Showing <span class="color-dark fw-500">{listings.length}</span> of <span class="color-dark fw-500">{count}</span> results</div>
                                    <div class="d-flex align-items-center xs-mt-20">
                                        <div class="short-filter d-flex align-items-center">
                                            <div class="fs-16 me-2">Short by:</div>
                                            <select onChange={async (e) => {
                                                const data = await fetchData(e.target.value);
                                                setListings(data.results);
                                                setCount(data.count);
                                                setPrevious(data.previous);
                                                setNext(data.next);
                                            }} class="nice-select">
                                                <option value="recent">Recent Added</option>
                                                <option value="high_price">High Price</option>
                                                <option value="low_price">Low Price</option>
                                            </select>
                                        </div>
                                        { /*<a href="listing_04.html" class="tran3s layout-change rounded-circle ms-auto ms-sm-3" data-bs-toggle="tooltip" title="Switch To List View"><i class="fa-regular fa-bars"></i></a>*/}
                                    </div>
                                </div>
                                <div class="row gx-xxl-5">
                                    {display}
                                </div>
                                <Pagination
                                    itemsPerPage={2}
                                    count={count}
                                    visitPage={visitPage}
                                    previous={previous_number}
                                    next={next_number}
                                    active={active}
                                    setActive={setActive}
                                />

                            </div>
                        </div>
                        <div class="col-lg-4 order-lg-first">
                            <div class="advance-search-panel dot-bg md-mt-80">
                                <div class="main-bg">
                                    <ListingForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Listings;
