import React from 'react'
import PropTypes from 'prop-types'
import Fancybox from "./Fancybox";
import ProjectAuthLink from '../hocs/ProjectAuthLink';

const ProjectCard = props => {

    const date = new Date(props.list_date);
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const formattedDate = `${month}/${year}`;

    return (
        <div class="isotop-item">
            <div class="project-block-three mt-80 lg-mt-50">
                <div class="row gx-xxl-5 align-items-center">
                    <div class="col-lg-6">
                        <Fancybox
                            options={{
                                Carousel: {
                                    infinite: false,
                                },
                            }}
                        >
                            <a href={props.photo_1} class="d-block position-relative" data-fancybox data-caption={props.title}>
                                <img src={props.photo_1} alt="" class="w-100 tran5s" width={570} height={495} />
                            </a>
                        </Fancybox>
                    </div>
                    <div class="col-lg-6 ms-auto">
                        <div class="caption ps-xxl-5">
                            <div class="tag fw-500 text-uppercase">{props.project_type}</div>
                            <br></br>
                            <ProjectAuthLink slug={props.slug} title={props.title} />
                            <p class="fs-24 pt-45 lg-pt-30 md-pt-10 pb-50 lg-pb-30 md-pb-10">Your leading real estate advocate.</p>
                            <p class="date bottom-line" style={{ color: '#FF6725'}}><b>{formattedDate}</b></p>
                            {/* <Link to={`/projects/${props.slug}`} class="btn-thirteen rounded-circle"><i class="bi bi-arrow-up-right"></i></Link> */}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ProjectCard.propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    photo_main: PropTypes.string.isRequired,
    photo_2: PropTypes.string.isRequired,
    photo_3: PropTypes.string.isRequired,
    photo_4: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    list_date: PropTypes.string.isRequired,
    project_type: PropTypes.string.isRequired,

}

export default ProjectCard